<template>
  <div>
    <DistanceFilterModal
      v-if="isDistanceFilterModalOpen"
      :is-visible="isDistanceFilterModalOpen"
      @close-modal="isDistanceFilterModalOpen = false"
    />

    <FeedbackModalComponent
      :is-open="modalSetup.isOpen"
      v-bind="modalSetup.props"
    />

    <FeedbackModalComponent
      :is-open="isBlockedModalOpen"
      :title="$t('Carsharing.Common.BlockedModal.title')"
      :image="genericUserBlock"
      :description="$t('Carsharing.Common.BlockedModal.text', {
        working_hours: customerCareOpeningHours,
        customer_service_phone: customerCarePhoneNumber,
      })"
      :primary-call-to-action-text="$t('buttons.got_it')"
      :primary-call-to-action="() => isBlockedModalOpen = false"
    />

    <LocationDetailsModal
      v-if="modals.locationsDetail.isOpen"
      v-bind="modals.locationsDetail"
      @close-modal="modals.locationsDetail.isOpen = false"
    />

    <TariffExpiredModalComponent
      v-model="isTariffExpiredModalOpen"
      :on-success="onSetDefaultTariffCallback"
      @close-modal="isTariffExpiredModalOpen = false"
    />

    <TariffWillExpireModalComponent
      v-model="isTariffWillExpireModalOpen"
      :expiry-date="tariffExpiryDate"
      :on-view-pricing-plans="onViewPricingPlans"
    />

    <PreferredLocationsModal
      :is-open="modals.preferredLocations.isOpen"
      :is-loading="modals.preferredLocations.isLoading"
      :current-favourite-locations="currentFavouriteLocations"
      @save-preferred-locations="updatePreferredLocations"
      @on:close-modal="modals.preferredLocations.isOpen = false"
    />

    <MissingLegalDocumentsComponent
      v-if="modals.missingLegalDocuments.isOpen"
      v-model="modals.missingLegalDocuments.isOpen"
      v-bind="modals.missingLegalDocuments.props"
    />

    <AvailabilitySuggestionsModal
      v-if="modals.availabilitySuggestions.isOpen"
      v-model="modals.availabilitySuggestions.isOpen"
      v-bind="modals.availabilitySuggestions.props"
      @on:close="() => {
        modals.availabilitySuggestions.isOpen = false;
      }"
    />
    <RecurringBookingsModal
      v-if="modals.recurringBookings.isOpen"
      v-model="modals.recurringBookings.isOpen"
      v-bind="modals.recurringBookings.props"
      @on:close="() => {
        modals.recurringBookings.isOpen = false;
        bookingModeSelected = BOOKING_MODES.regularBooking;
      }"
      @on:apply-recurrence="applyRecurrence"
    />

    <Component
      :is="modals.setup.module"
      v-if="modals.setup.props.isOpen"
      v-bind="modals.setup.props"
      v-on="modals.setup.listeners"
    />

    <MainViewLayout
      :class="{
        'no-overflow': isCollapsedFiltersOpen,
      }"
    >
      <template slot="main-content">
        <MapViewLayout>
          <template slot="left-top">
            <div
              v-if="showEmployeeSelector"
              class="BookingOnBehalfComponentWrapper p-4"
              data-outer-height
            >
              <BookingOnBehalfComponent
                v-if="hasResolveComponents"
                class="px-4"
                :user-data="userData"
                :selected-employee-uuid="get(employeeDriver, 'uuid')"
                @on-selected-employee="changeOnBehalfEmployee"
              />
            </div>
            <div>
              <div
                class="phBookingOptions d-flex flex-column"
                data-outer-height
              >
                <div class="d-flex align-items-end px-4 py-3">
                  <BookingTypeSelectorComponent
                    v-if="showBookingTypeSelector"
                    @changed:booking-type="onBookingTypeChange"
                  />

                  <BookingPodComponent
                    v-if="hasResolveComponents"
                    :booking-type="currentBookingType.getCode()"
                    :disabled="isMapShowingCityIcons || !isEmpty(selectedRecurringBooking) || !isEstimatedTripConfigured"
                    :class="[
                      'flex-fill',
                      { 'ml-4': showBookingTypeSelector },
                    ]"
                    @update:dates="onDatetimepickerChange"
                  />
                  <div class="position-relative">
                    <ui-button
                      v-if="hasResolveComponents && isFilterBarVisible"
                      :face="FACES.outline"
                      :color="GRAYSCALE.inkLighter"
                      :disabled="isMapShowingCityIcons"
                      narrow
                      class="ml-4"
                      data-test-id="filters_button"
                      @clickbutton="() => areFiltersOpened = !areFiltersOpened"
                    >
                      <div :class="['d-flex align-items-center', { 'emobg-color-ink': !isMapShowingCityIcons }]">
                        <ui-icon
                          :icon="ICONS.settingsSlider"
                          :size="ICONS_SIZES.medium"
                          class="mr-2"
                        />
                        {{ $t('algolia_table.filters') }}
                      </div>
                    </ui-button>
                    <span
                      v-if="filtersAppliedCounter && !isMapShowingCityIcons"
                      class="d-flex align-items-center justify-content-center emobg-background-color-primary
                      emobg-color-white emobg-border-radius-pill emobg-font-small position-absolute"
                      style="top: -7px; right: -7px; width: 20px; height: 20px; outline: 2px solid white;"
                    >
                      {{ filtersAppliedCounter }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="isFilterBarVisible"
                  v-show="areFiltersOpened"
                >
                  <AvailabilityFilters @apply-filters="refreshAvailability" />
                </div>
                <DistanceFilterBar v-if="isDistanceFilterBarVisible" />
              </div>
              <BookingModeSelector
                v-if="isBookingModeSelectorVisible && hasResolveComponents"
                :booking-mode="bookingModeSelected"
                :selected-recurring-booking="selectedRecurringBooking"
                class="px-5 py-4 phBookingOptions"
                @update:booking-mode-change="onBookingModeChange"
              />
            </div>
          </template>
          <template slot="left-content">
            <LocationList
              v-if="hasResolveComponents"
              :map-manager="mapManager"
              :selected-location="selectedLocation"
              :datetimepicker-manager="datetimepickerManager"
              :class="{ 'invisible': !isEstimatedTripConfigured }"
            />
            <AvailabilityFiltersAnimatedPanel v-if="isFilterBarVisible" />
          </template>
          <template slot="right-content">
            <div
              id="map-wrapper"
              class="Map_wrapper"
            >
              <template v-if="hasResolveComponents">
                <div class="phMap_searchWrapper px-5 pt-5 d-none d-md-block">
                  <Geocomplete
                    ref="geocompleteInput"
                    :current-position="isCenteredOnCurrentPosition"
                    prefered-locations
                    @center-user-position="centerMapInUserCurrentPosition"
                    @input-geocomplete-place="onChangeInputGeocomplete"
                    @open-preferred-location="openPreferredLocations"
                  />
                </div>
                <GoogleMaps
                  :manager="mapManager"
                  :location="{
                    lat: getUserGeolocation().getLatitude(),
                    lng: getUserGeolocation().getLongitude(),
                  }"
                />
              </template>
              <div
                v-else
                class="skeleton w-100 h-100"
              />
            </div>
          </template>
        </MapViewLayout>
      </template>
    </MainViewLayout>
  </div>
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import find from 'lodash/find';
import assign from 'lodash/assign';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import clone from 'lodash/clone';
import head from 'lodash/head';
import noop from 'lodash/noop';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import result from 'lodash/result';
import cloneDeep from 'lodash/cloneDeep';
import invoke from 'lodash/invoke';
import { computed, inject } from 'vue';
import moment from 'moment';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { aclService } from '@emobg/access-utils';
import {
  DATE_FORMAT,
  DELAY,
  FALLBACK_MESSAGE,
  findValue,
  getFirstItem,
  LOG_TYPE,
  logger,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import config from '@/config';
// Store
import * as BookingStore from '@/vue/stores/Booking/BookingStore';
import { ACTIONS as UserDataActions, nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';
import {
  MUTATIONS as DrivingLicenseRoadblockMutations,
  NAMESPACE as DrivingLicenseRoadblockNameSpace,
} from '@/components/DrivingLicenseRoadblock/DrivingLicenseRoadblockModule';
import {
  MUTATIONS as KeyCardRoadblockMutations,
  NAMESPACE as KeyCardRoadblockNameSpace,
} from '@/domains/Document/Keycard/KeyCardModule';
import {
  MUTATIONS as VehicleUserMutations,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';
import * as BookingModule from '@/stores/Booking/BookingModule';
import {
  currentFavouriteLocations,
  deleteFavouriteLocation,
  fetchFavouriteLocations,
  postFavouriteLocation,
} from '@/stores/FavouriteLocations/FavouriteLocationsMapper';
import {
  fetchCities,
  getCityCollection,
  getCityCollectionByProfile,
  getCurrentCity,
  setCurrentCity,
} from '@/stores/City/CityMapper';
import { getSelectedProfile, isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';

// Components
import MainViewLayout from '@/templates/MainLayout/MainLayout';
import MapViewLayout from '@/templates/MapViewLayout/MapViewLayout';
import Geocomplete from '@/vue/components/molecules/common/MapSearchBox/Geocomplete';
import GoogleMaps from '@Shared/components/Map/Map';
import ChangeTimeSlotModalComponent
  from '@/components/Booking/ChangeTimeSlotModalComponent/ChangeTimeSlotModalComponent';
import { AvailabilityFilters, AvailabilityFiltersAnimatedPanel } from '@/vue/components/organism/AvailabilityFilters';
import DistanceFilterBar from '@/domains/Bookings/components/DistanceFilter/DistanceFilterBar';
import BookingPodComponent from '@/domains/Bookings/components/BookingPod/BookingPodComponent';
import BookingTypeSelectorComponent
  from '@/domains/Bookings/components/BookingTypeSelector/BookingTypeSelectorComponent';

// Directives
import Clicked from '@/directives/Clicked';
import Hovered from '@/directives/Hovered';
// Mixins
import { City } from '@Shared/legacy/City';
import LoaderMixin from '@/mixins/Loader';
import EventHandlerMixin from '@/mixins/EventHandler';
import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';
import CSOperatorMixin from '@/mixins/CSOperator';
import BookingTypeMixin from '@/mixins/BookingType';
import CompanyAdminPaymentMethodMixin from '@/mixins/CompanyAdminPaymentMethod';
import ProfileMixin from '@/mixins/Profile';
import Notifications from '@/mixins/Notifications';
// Services
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import { geolocationManager } from '@/vue/managers/GeolocationManager';
import { MapManager } from '@/vue/managers/MapManager';
import AvailabilityAction from '@/vue/managers/AvailabilityAction';
import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';
import { toBookingTimer } from '@/helpers/booking/bookingTimeHelpers';
import { EMPLOYEE_STATUS } from '@/constants/employeeStatus';

// Composables
import { useDateTime } from '@/composable/Date/useDateTime';
import { useLocations } from '@/composable/Locations/useLocations';
import { useActiveTab } from '@/composable/App/useActiveTab';

// Constants
import { SEGMENT_ENUMERATE_VALUES, SEGMENT_EVENTS, SEGMENT_PARAM_NAMES } from '@/vue/constants';
import {
  ADD_PAYMENT_METHOD,
  CREATE_BOOKING_ON_BEHALF_EMPLOYEES,
  EDIT_PAYMENT_METHOD,
  INTERVENTION_BOOKING_TYPE,
  REGULAR_BOOKING_TYPE,
  VIEW_PAYMENT_METHODS,
} from '@/constants/permissions';
import { concatFilterByPermission, createFilterByPermissions } from '@/utils/algoliaHelpers';
import {
  genericErrorArgs,
  genericSuccessArgs,
  genericUserBlockArgs,
  genericWarningConfirmArgs,
} from '@/constants/defaultModalArgs';
import { BOOKING_MODES } from '@/constants/bookingModes';
import { downloadAppImage, genericUserBlock } from '@/utils/publicImages';
import ALERT_TYPES from '@/components/Alert/alertTypes';
// Router
import personalProfileRoutesNames from '@Profile/Personal/router/routes-names';
import businessProfileRoutesNames from '@Profile/Business/router/routes-names';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';

import { validationErrorMessageParams } from '@/helpers/booking/validationHelpers';
import { getRequestCode, removeRequestCode } from '@/helpers/costAllocation/costAllocationHelpers';

import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';
import UserValidationsModule from '@/stores/UserValidations/UserValidationsModule';
import {
  acceptLegalDocuments,
  getMissingLegalDocuments,
  missingLegalDocuments,
} from '@/stores/User/LegalDocuments/LegalDocumentsMapper';
import { getOperator } from '@/stores/Operator/OperatorMapper';

import {
  areRequiredDocumentsForCorporates,
  areRequiredDocumentsForCustomers,
  fetchRequiredDocuments,
  fetchUserValidations,
  hasCompletedDrivingLicense,
  hasValidationStatus,
  isValidStatusByType,
} from '@/stores/UserValidations/UserValidationsMapper';
import { hasDedicatedFleet } from '@/stores/Company/CompanyMapper';

import PreferredLocationsModal from '@/components/PreferredLocations/PreferredLocationsModal';
import RecurringBookingsModal from '@/components/Modals/RecurringBookingsModal/RecurringBookingsModal';
import BookingModeSelector from '@/components/BookingModeSelector/BookingModeSelector';
import { getCurrentOperatorCurrency } from '@/stores/CSOperator/CSOperatorMapper';
import {
  ACTIONS as OperatorLocationsActions,
  NAMESPACE as OperatorLocationsNamespace,
} from '@/stores/OperatorLocations/OperatorLocationsModule';
import { nameSpace as bookingTypeNameSpace } from '@/vue/stores/BookingType/BookingTypeStore';
import SESSION_STORAGE from '@/constants/session-storage';
import { USER_STATUS } from '@/constants/userStatus.const';
import AvailabilitySuggestionsModal
  from '@/components/Modals/AvailabilitySuggestionsModal/AvailabilitySuggestionsModal';
import { USER_VALIDATION_STATUS } from '@/constants/userValidation';
import { useSegment } from '@/composable/Segment/segment';
import { useAvailability } from '@/composable/Availability/useAvailability';
import { useAvailabilityFilter } from '@/composable/Availability/useAvailabilityFilters';
import { useAuth } from '@/composable/Api/Auth/auth';
import { useCity } from '@/composable/City/useCity';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { SEGMENT_EVENTS as SEGMENT_EVENTS_NAME } from '@/constants/segment';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { mapLocationData } from '@/helpers/user/tracking/ecommerceEvents';
import { GTM_EVENTS, GTM_MODAL_EVENTS } from '@/constants/gtm';
import { MARKER_STATES } from '@/constants/map.const';
import { PROFILE_TYPES } from '@/constants/profiles.const';
import DistanceFilterModal from '@/domains/Bookings/components/DistanceFilter/DistanceFilterModal';
import { NOTIFICATION_DEFAULT_PRIORITY } from '@/components/NotificationList/constants/notification.const';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import CustomerValidationComponent
  from '@/domains/Bookings/components/UserValidationModals/CustomerValidationComponent';
import LocationDetailsModal from '@/vue/components/organism/common/LocationDetails/LocationDetailsModal';
import CorporateValidationComponent from './components/UserValidationModals/CorporateValidationComponent';
import WaitingForValidationModalComponent from './components/UserValidationModals/WaitingForValidationModalComponent';
import BookingOnBehalfComponent from './components/BookingOnBehalf/BookingOnBehalfComponent';
import TariffExpiredModalComponent from './components/TariffExpiredModal/TariffExpiredModalComponent';
import TariffWillExpireModalComponent from './components/TariffWillExpireModal/TariffWillExpireModalComponent';
import MissingLegalDocumentsComponent from './components/MissingLegalDocuments/MissingLegalDocumentsComponent';
import BookingRoutesNames from './router/routes-names';
import { setDefaultDates } from './utils/setDefaultDates/setDefaultDates';

import { CS_OPERATOR_UUIDS } from './constants/operators.const';

const LocationList = () => import(/* webpackChunkName: "location-list" */'./components/Location/LocationList');

export default {
  name: 'Home',
  components: {
    DistanceFilterModal,
    DistanceFilterBar,
    AvailabilityFilters,
    AvailabilityFiltersAnimatedPanel,
    BookingOnBehalfComponent,
    BookingPodComponent,
    BookingTypeSelectorComponent,
    ChangeTimeSlotModalComponent,
    FeedbackModalComponent,
    Geocomplete,
    GoogleMaps,
    LocationDetailsModal,
    LocationList,
    MainViewLayout,
    MapViewLayout,
    MissingLegalDocumentsComponent,
    PreferredLocationsModal,
    TariffExpiredModalComponent,
    TariffWillExpireModalComponent,
    AvailabilitySuggestionsModal,
    RecurringBookingsModal,
    BookingModeSelector,
  },
  directives: {
    Hovered,
    Clicked,
  },
  mixins: [
    LoaderMixin,
    AuthenticatedUserMixin,
    EventHandlerMixin,
    CSOperatorMixin,
    BookingTypeMixin,
    CompanyAdminPaymentMethodMixin,
    ProfileMixin,
    Notifications,
  ],
  beforeRouteLeave(to, from, next) {
    /**
       * When we leave the map route, where we can have multiple
       * operator configurations via "visitedCsOperator", set visited
       * operator to null so the returned value it will be the user operator.
       * Except when we go to booking summary as we want keep the operator
       * of the booking.
       * */
    if (to.name !== BookingRoutesNames.bookingSummary) {
      this.setVisitedCsOperator(null);
    } else {
      this.setEstimatedTripMileage(null);
    }
    next();
  },
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
    auth: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { logout } = useAuth();
    const { trackSegment } = useSegment();
    const {
      currentActiveNotification,
      notifyWarning,
      notifyError,
      notifySuccess,
      removeAllNotifications,
    } = useNotifications();
    const {
      trackPageView, trackModalView, trackCartEvent, trackEcommerceEvent,
    } = useTrackingGTM();
    const hasNotification = computed(() => !isEmpty(get(currentActiveNotification, 'value')));
    const {
      setAvailabilityFiltersDirectly,
      setOldAvailabilityFilters,
      isCollapsedFiltersOpen,
      isSomeFilterApplied,
    } = useAvailabilityFilter();
    const {
      oldStartAvailabilityValue,
      oldEndAvailabilityValue,
      locationsAvailables,
      availabilityRange,
      getProfileUuidSelected,
      addBeforeAvailabilityAction,
      addAfterAvailabilityAction,
      setOldCityValue,
      setAvailabilityResponse,
      resetAvailabilityStore,
      setWaitingGeolocation,
      setEmployeeProfile,
      getUserAvailability,
      currentEmployeeProfile,
      isBookingOnBehalf,
      filtersAppliedCounter,
    } = useAvailability();

    const {
      getCitiesBounds,
      isLocationInSomeCity,
      getCityMostClose,
      getUnknownCity,
      isUnknownCity,
    } = useCity();

    const flavor = inject('flavor');

    const {
      currentStartDateTime,
      currentEndDateTime,
      setCurrentStartDateTime,
      setCurrentEndDateTime,
      dateFormat,
    } = useDateTime();

    const { fetchLocationsByCity, setLocationCollection } = useLocations();

    const { setActiveTabValue } = useActiveTab();

    return {
      logout,
      trackSegment,
      setAvailabilityFiltersDirectly,
      setOldAvailabilityFilters,
      isCollapsedFiltersOpen,
      isSomeFilterApplied,
      oldStartAvailabilityValue,
      oldEndAvailabilityValue,
      locationsAvailables,
      availabilityRange,
      getProfileUuidSelected,
      addBeforeAvailabilityAction,
      addAfterAvailabilityAction,
      setOldCityValue,
      setAvailabilityResponse,
      resetAvailabilityStore,
      setWaitingGeolocation,
      setEmployeeProfile,
      getUserAvailability,
      currentEmployeeProfile,
      isBookingOnBehalf,
      getCitiesBounds,
      isLocationInSomeCity,
      getCityMostClose,
      getUnknownCity,
      isUnknownCity,
      trackPageView,
      trackModalView,
      trackCartEvent,
      trackEcommerceEvent,
      currentActiveNotification,
      notifyWarning,
      notifyError,
      notifySuccess,
      removeAllNotifications,
      hasNotification,
      flavor,
      currentStartDateTime,
      currentEndDateTime,
      setCurrentStartDateTime,
      setCurrentEndDateTime,
      dateFormat,
      fetchLocationsByCity,
      setLocationCollection,
      setActiveTabValue,
      filtersAppliedCounter,
    };
  },

  data() {
    return {
      flag: {
        resolveComponents: false,
      },
      isDistanceFilterModalOpen: false,
      selectedLocation: null,
      mapManager: new MapManager(),
      datetimepickerManager: null,
      employeeDriver: null,
      timerMapBouncing: null,
      cityIconWasClicked: false,
      operatorConfig: null,
      firstTimeNotificationGeoLocation: true,
      isMapLoaded: false,
      isMapShowingCityIcons: true,
      isTariffExpiredModalOpen: false,
      isTariffWillExpireModalOpen: false,
      isCenteredOnCurrentPosition: false,
      isBlockedModalOpen: false,
      bookingSummary: {
        params: null,
      },
      modalSetup: {
        isOpen: false,
        props: {},
      },
      modals: {
        changeTimeSlot: {
          isOpen: false,
          props: {},
        },
        preferredLocations: {
          isOpen: false,
          gmaApi: undefined,
        },
        missingLegalDocuments: {
          isOpen: false,
          props: {},
        },
        availabilitySuggestions: {
          isOpen: false,
          props: {},
        },
        locationsDetail: {
          isOpen: false,
          mapManager: this.mapManager,
          parentLocation: null,
        },
        recurringBookings: {
          isOpen: false,
          props: {},
        },
        setup: {
          isOpen: false,
          module: null,
          props: {},
          listeners: {},
        },
      },
      selectedRecurringBooking: {},
      bookingModeSelected: BOOKING_MODES.regularBooking,
      areFiltersOpened: false,
    };
  },

  computed: {
    missingLegalDocuments,
    currentFavouriteLocations,
    // UserValidationsMapper
    areRequiredDocumentsForCorporates,
    areRequiredDocumentsForCustomers,
    hasValidationStatus,
    hasCompletedDrivingLicense,
    isValidStatusByType,
    getCurrentCity,
    getCityCollectionByProfile,
    getCityCollection,
    getCurrentOperatorCurrency,
    isBusinessProfileActive,
    getSelectedProfile,
    hasDedicatedFleet,

    ...mapGetters(OperatorLocationsNamespace, ['oneWayLocations', 'operatorLocations']),
    ...mapState(BookingStore.nameSpace, {
      booking: state => state.reservation,
      estimatedTripMileage: state => state.estimatedTripMileage,
    }),

    ...mapGetters(BookingStore.nameSpace, [
      'newBookingHash',
      'getBookingEncoded',
    ]),

    ...mapGetters(UserValidationsModule.NAMESPACE, [
      'citiesFetchedByBookingType',
      'validationsWithoutDocument',
      'userValidationsByBookingTypeOperator',
      'isValidStatusByType',
    ]),

    ...mapGetters(BookingModule.NAMESPACE, {
      bookingRulesData: BookingModule.GETTERS.bookingRulesData,
      isBookingBetweenRules: BookingModule.GETTERS.isBookingBetweenRules,
    }),

    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      {
        externalBookingData: state => state.externalBooking,
      },
    ),

    ...mapGetters(bookingTypeNameSpace, {
      currentBookingType: 'getCurrentBookingType',
    }),

    ...mapState(UserDataNameSpace, {
      isCompanyPays: state => get(state, 'userData.company.company_pays'),
      companyStatus: state => get(state, 'userData.company.status'),
    }),
    hasPromoteVehiclesInSearchResult() {
      return get(this.activeOperatorConfig, 'configuration.promote_vehicles_in_the_search_result', false);
    },
    isDistanceFilterBarVisible() {
      return !this.isMapShowingCityIcons && this.hasPromoteVehiclesInSearchResult;
    },
    isFilterBarVisible() {
      return this.hasPromoteVehiclesInSearchResult ? !!this.estimatedTripMileage && !this.isMapShowingCityIcons : get(this.activeOperatorConfig, 'configuration.availability_filters_enabled');
    },
    isEstimatedTripConfigured() {
      return this.isDistanceFilterBarVisible ? !!this.estimatedTripMileage : true;
    },
    isBusinessProfile() {
      return result(this, 'currentProfile.isBusinessProfile', false);
    },
    isPaymentConfirmed() {
      return this.booking.isPaymentConfirmed;
    },
    hasResolveComponents() {
      return get(this, 'flag.resolveComponents');
    },
    showEmployeeSelector() {
      return aclService.hasPermissions(CREATE_BOOKING_ON_BEHALF_EMPLOYEES)
        && this.currentProfile.isBusinessProfile() && this.isCompanyPays;
    },
    filterSelectorEmployees() {
      /**
       * If user has a role to create bookings on behalf
       * we have to show all employees of the company. We filter
       * by circles when the user is a regular employee but
       * he can manage a circle
       */
      const arrUuids = createFilterByPermissions(
        aclService,
        CREATE_BOOKING_ON_BEHALF_EMPLOYEES,
      );

      return concatFilterByPermission({
        currentFilter: `company_uuid:'${get(this, 'userData.company.uuid')}' AND employee_status:'${EMPLOYEE_STATUS.activated}'`,
        circlesUuids: arrUuids,
        field: 'circles',
        extraCircleCondition: ` OR uuid:'${get(this, 'userData.uuid')}'`,
      });
    },
    showBookingTypeSelector() {
      const operatorAllowLD = this.getVisitedCsOperator
        && this.getVisitedCsOperator.configuration.long_distance_allowed;
      const childOperatorAllowLD = this.getCompanyData
        && get(this, 'operatorConfig.configuration.long_distance_allowed');
      const isBusinessProfile = this.currentProfile.alias === PROFILE_TYPES.business;
      const useOpenInDedicated = this.getCompanyData
        && this.getCompanyData.allows_business_use_open;

      // User has a company and
      // Is in business profile
      // return TRUE if dedicated can use open and PARENT operator allow LD
      // or return if CHILD operator allow LD
      if (isBusinessProfile) {
        return ((useOpenInDedicated && operatorAllowLD) || childOperatorAllowLD) && !this.isMapShowingCityIcons;
      }

      // User is in personal profile and allow LD
      return operatorAllowLD && !this.isMapShowingCityIcons;
    },

    isBookingModeSelectorVisible() {
      return get(this.operatorConfig, 'configuration.uses_pre_booking', false)
        && get(this.operatorConfig, 'configuration.allow_recurring_bookings', false)
        && this.isBusinessProfileActive;
    },

    isBookingBehalfEmployee() {
      return this.showEmployeeSelector ? this.isBookingOnBehalf : false;
    },

    userHasTariff() {
      return !isNil(this.userData.getTariff());
    },
    isBusinessProfileWithoutOpenTariff() {
      const company = get(this, 'userData.company');
      return this.isBusinessProfile && get(company, 'fleets.open') && !get(company, 'tariff.open');
    },
    tariffExpiryDate() {
      return get(this, 'userData.tariff.expiry_date') || '';
    },

    customerCarePhoneNumber() {
      const userCsOperator = this.userData.getCarsharingOperatorUUID();
      const csOperatorData = this.findCSOperatorByUUID(userCsOperator);
      return get(csOperatorData, 'customer_service_phone', '');
    },

    customerCareOpeningHours() {
      const userCsOperator = this.userData.getCarsharingOperatorUUID();
      const csOperatorData = this.findCSOperatorByUUID(userCsOperator);

      return get(csOperatorData, 'customer_service_opening_hours', '');
    },

    isUserAtHomeland() {
      return this.getVisitedCsOperator.getUUID() === this.getUserData().getCarsharingOperatorUUID();
    },

    // Returns CS Operator config to apply in place in the map
    // If user it's on his own country, must use his child/parent CS Operator
    // otherwise, it should apply the CS Operator from the city visited on the map
    activeOperatorConfig() {
      return this.isUserAtHomeland
        ? this.operatorConfig
        : this.getVisitedCsOperator;
    },

    canMakeRegularBookings() {
      return aclService.hasUserPermissions(REGULAR_BOOKING_TYPE);
    },

    canMakeInterventionBookings() {
      return aclService.hasUserPermissions(INTERVENTION_BOOKING_TYPE);
    },
  },

  watch: {
    isMapShowingCityIcons() {
      if (this.isMapShowingCityIcons) {
        this.areFiltersOpened = false;
      }
    },
    estimatedTripMileage(newTripMileage) {
      if (newTripMileage) {
        this.setAvailabilityFiltersDirectly([]);
        this.refreshAvailability();
      }
    },
    isBusinessProfile() {
      this.onChangeProfile();
    },
    activeOperatorConfig(operator) {
      const operatorUuid = get(operator, 'uuid');
      const hasOneWay = get(operator, 'configuration.has_one_way');

      if (!hasOneWay
        && (!operatorUuid || !get(this, '$featureFlag.flags.toggleSearchLocationTypes'))
      ) {
        return;
      }

      this.fetchOperatorLocations({ operatorUuid });
    },
    currentActiveNotification(notification) {
      this.setHeightInVehicleList(!!notification.text);
    },
    isPaymentConfirmed() {
      this.trackSegment({ name: SEGMENT_EVENTS.ADD_PAYMENT_METHOD_CONFIRMED });
    },

    getSelectedProfile(newUuid) {
      if (newUuid) {
        this.refreshAvailability();
      }
    },
  },

  async created() {
    this.genericUserBlock = genericUserBlock;
    this.appName = config.data.appName;
    this.BOOKING_MODES = BOOKING_MODES;
    this.toggleLoaderStatus(true);
    const company = this.getUserData().getCompany();
    const operatorUuid = get(company, 'dedicated_fleet_cs_operator') || this.getUserData().getCarsharingOperatorUUID();
    await this.checkBookingTypesPermissions();

    if (!this.canMakeRegularBookings) {
      return;
    }

    try {
      // Reset Availability Store. Clean data!
      this.resetAvailabilityStore([
        'employee',
      ]);

      /**
       * For each profile,
       * create cities and keep them on SessionStorage,
       * save the city collection of the current profile
       */
      let indexActiveProfile = 0;
      const citiesRequest = map(this.profileCollection, (profile, index) => {
        if (profile.uuid === this.currentProfile.uuid) {
          indexActiveProfile = index;
        }
        this.fetchCities({ userProfileUuid: profile.uuid });
        return this.getCityCollection(profile.uuid);
      });

      const citiesResponse = await Promise.all(citiesRequest);
      const activeCities = get(citiesResponse, indexActiveProfile, []);

      // TODO Remove City object-values [https://europcarmobility.atlassian.net/browse/CF-480]
      const citiesByProfile = map(activeCities, city => new City(city));
      await this.setupMapManager(citiesByProfile);

      const requestCode = getRequestCode();
      if (requestCode && company && !this.externalBookingData) {
        await this.fetchExternalBooking({
          companyUuid: company.uuid,
          token: requestCode,
        }).catch(() => {
          logger.message('Error on get request code', LOG_TYPE.error);
          removeRequestCode();
          this.setExternalBooking(null);
        });
      }

      this.getCSOperator(operatorUuid, company);
      this.setDefaultEmployeeDriver();
    } catch ({ message }) {
      logger.message(message, LOG_TYPE.error);
      this.eventHandler.$emit(this.events.SHOW_ERROR_MODAL_PAGE, {
        ...genericErrorArgs(this.$t),
        error: this.$t('notifications.whooops'),
      });

      this.toggleLoaderStatus(false);
    }
  },
  mounted() {
    const callSetHeightInVehicleList = () => this.setHeightInVehicleList(this.hasNotification);
    window.addEventListener('resize', debounce(callSetHeightInVehicleList, DELAY.medium));
    this.checkFeedbackModal();
  },
  beforeDestroy() {
    this.eventHandler.$off(this.events.MAP_LOADED);
    this.eventHandler.$off(this.events.MAP_BOUNCING);
    this.eventHandler.$off(this.events.ZOOM_MAP_CHANGING);
    this.eventHandler.$off(this.events.SHOW_BOOKING_SUMMARY);
    this.eventHandler.$off(this.events.GO_BOOKING_CONFIRM);
    window.removeEventListener('resize', this.setHeightInVehicleList(this.hasNotification));
  },

  methods: {
    get,
    size,
    isEmpty,
    invoke,
    getMissingLegalDocuments,
    acceptLegalDocuments,
    fetchFavouriteLocations,
    postFavouriteLocation,
    deleteFavouriteLocation,
    setCurrentCity,
    fetchCities,
    async isPinInSameCity({ geocompleteMarker, isPinVisible }) {
      const pinCity = isPinVisible
        ? await this.isLocationInSomeCity({
          lat: geocompleteMarker.getPosition().lat(),
          lng: geocompleteMarker.getPosition().lng(),
        })
        : false;

      return get(this, 'currentCity.uuid') === get(pinCity, 'uuid');
    },
    async setupMapManager(citiesByProfile) {
      await googleMapsLoader.waitUntilGoogleMapsIsAttached();
      const citiesBounds = this.getCitiesBounds(citiesByProfile);
      const centerCitiesBounds = citiesBounds.getCenter();

      geolocationManager.setLatitudeAndLongitude(
        centerCitiesBounds.lat(),
        centerCitiesBounds.lng(),
      );
      this.toggleLoaderStatus(false);
      const geolocation = await geolocationManager.getNavigatorGeolocation();

      this.setUserGeolocation(geolocation);
      this.eventHandler.$on(this.events.MAP_LOADED, this.onMapLoaded);
    },
    getCSOperator(operatorUuid, company) {
      this.fetchCSOperator(operatorUuid)
        .then(async operator => {
          this.setAvailabilityFiltersDirectly([]);
          this.setOldAvailabilityFilters();
          this.setVisitedCsOperator(operator);

          this.operatorConfig = operator;

          // If user is an employee, we have to check if
          // he is a Santander employee to set a other marker
          const isSantander = get(company, 'dedicated_fleet_cs_operator') === CS_OPERATOR_UUIDS.santander || false;
          const markerConfig = isSantander ? config.brands.santander : config.data.flavor;
          mapMarkerSelector.setOperator(markerConfig);

          const { start, end } = setDefaultDates(this);

          if (start && end) {
            const operatorConfig = cloneDeep(this.activeOperatorConfig);
            this.datetimepickerManager = this.getBookingRulesByCurrentBookingType(
              assign(
                operatorConfig,
                {
                  start: moment(start),
                  end: moment(end),
                },
              ),
            );

            this.setExternalBooking({
              ...this.externalBookingData,
              start: null,
              end: null,
              user_uuid: null,
            });
          }

          if (!this.datetimepickerManager) {
            this.datetimepickerManager = this.getBookingRulesByCurrentBookingType(this.activeOperatorConfig);
          }

          this.setCurrentStartDateTime(this.datetimepickerManager.getStart());
          this.setCurrentEndDateTime(this.datetimepickerManager.getEnd());

          // Resolve components
          Object.assign(this.flag, {
            resolveComponents: true,
          });

          this.showNotificationsOrRoadblock();
        });
    },
    applyRecurrence({
      datetimepickerManager, recurrenceOption, limitDate, customOption, periods,
    }) {
      const { start, end } = datetimepickerManager;
      this.datetimepickerManager.setStart(start);
      this.datetimepickerManager.setEnd(end);

      // TODO: Decide what to do on next MR
      // 1. Keep on View (Home)
      // 2. Create a specific Store for Booking mode (current pattern, like BookingType)
      // 3: Create new store to persist "current booking options" on Home

      this.selectedRecurringBooking = {
        datetimepickerManager,
        recurrenceOption,
        limitDate,
        customOption,
        periods,
      };

      this.setCurrentStartDateTime(start);
      this.setCurrentEndDateTime(end);

      this.refreshAvailability();
      this.modals.recurringBookings.isOpen = false;
    },
    onBookingModeChange(bookingMode) {
      this.bookingModeSelected = bookingMode;
      if (bookingMode === BOOKING_MODES.recurringBooking) {
        const operatorConfig = cloneDeep(this.activeOperatorConfig);
        const recurrenceLimit = get(this.operatorConfig, 'configuration.max_recurring_bookings', 0);

        let recurringBookingInitialData = this.selectedRecurringBooking;

        if (isEmpty(recurringBookingInitialData)) {
          const start = this.datetimepickerManager.getStart();
          const end = this.datetimepickerManager.getEnd();

          const datetimepickerManager = this.getBookingRulesByCurrentBookingType(assign(
            operatorConfig,
            {
              start: moment(start),
              end: moment(end),
            },
          ));
          recurringBookingInitialData = {
            datetimepickerManager,
            limitDate: clone(datetimepickerManager.end),
          };
        }

        this.modals.recurringBookings.props = {
          ...recurringBookingInitialData,
          recurrenceLimit,
        };
        this.modals.recurringBookings.isOpen = true;
      } else {
        this.selectedRecurringBooking = {};
      }
    },
    checkFeedbackModal() {
      const feedabackModal = window.sessionStorage.getItem(SESSION_STORAGE.feedbackModal);
      if (!feedabackModal) {
        return;
      }

      this.openSignUpCompleteModal({ type: feedabackModal });
      window.sessionStorage.removeItem(SESSION_STORAGE.feedbackModal);
    },
    setHeightInVehicleList(isNotificationVisible = false) {
      const defaultHeight = 64;
      const expandedHeight = 124;

      const filterElement = document.getElementById('map-view-filters');
      const vehicleListElement = document.getElementById('map-view-vehicles');
      const mapElement = document.getElementById('map-wrapper');
      if (!isNil(mapElement)) {
        mapElement.style.top = isNotificationVisible ? `${expandedHeight}px` : `${defaultHeight}px`;
        mapElement.style.height = isNotificationVisible ? '88vh' : '95vh';
      }

      if (!isNil(filterElement) && !isNil(vehicleListElement)) {
        filterElement.style.top = isNotificationVisible ? `${expandedHeight}px` : `${defaultHeight}px`;
      }
    },

    activatedBehaviour() {
      if (!this.userHasTariff && this.currentProfile.isPersonalProfile()) {
        this.isTariffExpiredModalOpen = true;
      }
    },

    blockedBehaviour() {
      this.isBlockedModalOpen = true;
      this.notifyError({
        text: this.$t('notifications.user_status_blocked'),
        textAction: this.$t('common.more_info'),
        isClosable: false,
        action: () => { this.isBlockedModalOpen = true; },
      }, NOTIFICATION_DEFAULT_PRIORITY.maxPriority);
    },

    companyHasNotTariff() {
      const hasBusinessProfile = this.currentProfile.getCompanyData();

      return hasBusinessProfile && !!hasBusinessProfile.getCompanyTariff() === false;
    },

    showNotificationsOrRoadblock() {
      this.deleteAllNotifications();
      this.removeAllNotifications(true);

      // TODO: !! Extract this code in several methods :|
      if (this.showModalCompanyHasNotPaymentMethod()) {
        this.openPaymentMethodMissingModal();
      } else if (this.userData.isUserEmailVerify() && this.isBusinessProfileWithoutOpenTariff) {
        this.isTariffExpiredModalOpen = true;
      } else if (this.currentProfile.isPersonalProfile()) {
        switch (this.userData.getStatus()) {
          case USER_STATUS.unpaid:
            if (this.currentProfile.isPersonalProfile()) {
              // Set invoice tab
              this.setActiveTabValue('ProfileTab__Invoices');

              // Create notification with an error and an action (redirect to invoice list page)
              this.notifyError({
                text: this.$t('notifications.user_status_unpaid'),
                textAction: this.$t('notifications.user_status_unpaid_button'),
                action: () => {
                  this.$router.push({ name: personalProfileRoutesNames.invoices });
                },
              });
            }
            break;

          case USER_STATUS.activated:
            this.activatedBehaviour();
            break;

          case USER_STATUS.blocked:
            this.blockedBehaviour();
            break;

          default:
            break;
        }
      } else if (this.currentProfile.isBusinessProfile() && this.isCompanyPays) {
        if (this.companyStatus === USER_STATUS.unpaid) {
          this.notifyError({
            text: this.$t('notifications.company_status_unpaid'),
            isClosable: false,
          }, NOTIFICATION_DEFAULT_PRIORITY.maxPriority);
        }
      } else if (this.userData.hasPendingLogbook()) {
        this.openLogbookModal();
      }
    },
    openPaymentMethodMissingModal() {
      this.modalSetup = {
        isOpen: true,
        props: {
          ...genericWarningConfirmArgs(this.$t),
          title: this.$t('modal.payment_method.not_found_error.title'),
          description: this.$t('modal.payment_method.not_found_error.body_text_1'),
          extraInfo: this.$t('modal.payment_method.not_found_error.body_text_2'),
          primaryCallToAction: () => {
            this.setActiveTabValue('CompanyAdminProfile__CompanyPaymentMethod');
            this.$router.push({ name: CompanyAdminRoutesNames.paymentMethods });
            this.closeModal();
          },
          primaryCallToActionText: this.$t('modal.payment_method.not_found_error.do_it_now'),
          secondaryCallToAction: this.closeModal,
          secondaryCallToActionText: this.$t('modal.payment_method.not_found_error.do_it_later'),
        },
      };
    },
    openLogbookModal(severityError) {
      const isErrorCode = this.userData.isLogbookLast() || severityError === ALERT_TYPES.error;
      const alertSeverityCode = isErrorCode
        ? ALERT_TYPES.error
        : ALERT_TYPES.warning;

      this.modalSetup = {
        isOpen: true,
        props: {
          ...genericWarningConfirmArgs(this.$t),
          title: this.$t(`logbook.${alertSeverityCode}_modal.title`),
          description: this.$t(`logbook.${alertSeverityCode}_modal.description`),
          primaryCallToAction: () => this.$router.push({ name: BookingRoutesNames.myBookingsLogbook }),
          primaryCallToActionText: this.$t('buttons.ok'),
          secondaryCallToAction: this.closeModal,
          secondaryCallToActionText: this.$t('buttons.back'),
        },
      };
    },

    showModalCompanyHasNotPaymentMethod() {
      const canUserChangePaymentMethod
          = aclService.hasPermissions(VIEW_PAYMENT_METHODS)
          && aclService.hasPermissions(ADD_PAYMENT_METHOD)
          && aclService.hasPermissions(EDIT_PAYMENT_METHOD);
      return this.currentProfile.isBusinessProfile()
          && canUserChangePaymentMethod
          && this.userData.isUserEmailVerify()
          && !this.userData.getPermissions().canChangeBusinessProfilePaymentMethod()
          // It comes from "src/vue/mixins/CompanyAdminPaymentMethod.js"
          && !this.hasCompanyAnyPaymentMethod
          && !this.hasCompanyAnyInternalPaymentMethod;
    },

    async onMapBouncing() {
      // Prevent to do these actions every time
      // map is dragged
      if (!this.timerMapBouncing) {
        // Actions will be done every second
        const seconds = 1000;
        this.timerMapBouncing = setTimeout(async () => {
          // Get Latitude and Longitude of the center of the map
          const lat = this.mapManager.getMapCenterLatitude();
          const lng = this.mapManager.getMapCenterLongitude();

          // Center of the map is in some city?
          const isIn = await this.isLocationInSomeCity({ lat, lng });

          // Yes!! User is in an Ubeeqo city
          const oldCity = { ...this.getCurrentCity };
          if (isIn && !this.mapManager.isMapShowingCityIcons()) {
            // Is this city different to city saved previously?
            if (this.getCurrentCity && get(this, 'getCurrentCity.uuid') !== isIn.getUUID()) {
              // Yes, so we have to save the new city
              // to call later the Availability api later
              this.setCurrentCity(isIn);

              await this.getUserCityValidations({ cityUuid: isIn.uuid, bookingType: this.currentBookingType.code });

              // Maybe Map could be waiting to know some geolocation
              // of the user, it could be geolocation marker or user marker.
              // When Map is moved and it finds a new city
              // it has to be unlocked
              this.setWaitingGeolocation(false);

              // We prepare Availability store to call the Api
              // First, we have to set to NULL, so UI will show
              // a placeholder loader
              this.setAvailabilityResponse(null);

              // Get the locations of the new city
              // to be recommended in search box of the map
              try {
                const locations = await this.fetchLocationsByCity(isIn.getUUID());
                this.setLocationCollection(locations);
              } catch (error) {
                logger.message('Error catched: retrieving locations by city', LOG_TYPE.error);
                this.setLocationCollection([]);
                this.notifyError({ text: error.message });
              }

              // When we have not set Visited CS Operator or
              // it's different of the visited city
              // we have to set it!
              // When it's different, we have to get his
              // configuration and configure the Datetimepickers
              const operator = await this.fetchCSOperator(isIn.getCSOperatorUUID());
              const isSameOperator
                = this.getVisitedCsOperator.getUUID() === isIn.getCSOperatorUUID();

              if (isNil(this.getVisitedCsOperator) || !isSameOperator) {
                const carsharingBookingType = findValue(
                  this.bookingTypeCollection,
                  { code: BOOKING_TYPES.carsharing },
                ) || getFirstItem(this.bookingTypeCollection);
                await this.setCurrentBookingType(carsharingBookingType);

                this.setAvailabilityFiltersDirectly([]);
                this.setOldAvailabilityFilters();
                this.setVisitedCsOperator(operator);

                const operatorConfig = cloneDeep(operator);
                const start = this.datetimepickerManager.getStart();
                const end = this.datetimepickerManager.getEnd();
                this.datetimepickerManager
                  = this.getBookingRulesByCurrentBookingType(assign(
                    operatorConfig,
                    {
                      start: moment(start),
                      end: moment(end),
                    },
                  ));
              }

              // Finally, call Api Availability
              this.$nextTick(async () => {
                try {
                  /**
                   * If its another operator the Booking Pod resets and triggers the availability with
                   * the new dates according the configuration of new operator.
                   * Else call availability if same operator because Booking Pod does not trigger anything as
                   * operator configuration does not change.
                   */
                  if (isSameOperator) {
                    this.refreshAvailability();
                  }

                  if (oldCity.uuid !== isIn.getUUID()) {
                    const gpsLat = get(this.booking.pickUpLocation, 'gps_lat', 0);
                    const gpsLng = get(this.booking.pickUpLocation, 'gps_lng', 0);

                    if (gpsLat && gpsLng) {
                      const position = MapManager.getGoogleMapsLatLong(gpsLat, gpsLng);

                      const pickUpCity = await this.isLocationInSomeCity({
                        lat: position.lat(),
                        lng: position.lng(),
                      });

                      if (get(pickUpCity, 'uuid') !== isIn.getUUID()) {
                        this.setBooking({
                          pickUpLocation: null,
                        });
                      }
                    }
                  }
                } catch ({ message }) {
                  this.notifyError({
                    text: message,
                    textAction: this.$t('buttons.ok'),
                  });
                }
              });
            }
          }

          // Clean timeout
          // User can move again by the map
          this.$nextTick(() => {
            clearTimeout(this.timerMapBouncing);
            this.timerMapBouncing = null;
          });
        }, seconds);
      }

      if (geolocationManager.isActive()) {
        const mapLat = this.mapManager.getMapCenterLatitude();
        const mapLong = this.mapManager.getMapCenterLongitude();
        this.isCenteredOnCurrentPosition = geolocationManager.isCloseOfMyPosition(mapLat, mapLong);
      }
    },

    async onZoomMapChanging() {
      const google = googleMapsLoader.getInstance();

      // Probably this condition is not necessary,
      // but I check it just in case
      if (!(this.mapManager && google)) {
        return;
      }

      this.isMapShowingCityIcons = this.mapManager.isMapShowingCityIcons();

      // When map is done zoom, could be two possibilities
      // 1. Zoom is lower than X = this.mapManager.getZoomLevelToShowCityMarkers()
      // In this case, we have to remove the markers and clusters
      // and show the cities icons. User is free to navigate for all
      // map without any Availability call
      //
      // 2. Zoom is greater or equals to X = this.mapManager.getZoomLevelToShowCityMarkers()
      // In this case, we have to remove city icons and add the markers
      // and clusters of the current city
      //
      // These actions are done in onMapBouncing() method

      // First case, when city icons are showed
      if (this.isMapShowingCityIcons) {
        this.onZoomMapChangingToShowCitiesIcon();
        this.setVisitedCsOperator(null);
        this.setAvailabilityResponse({ locations: [] });
        this.setOldCityValue(null);
        this.setCurrentCity(this.getUnknownCity());
        this.setBooking({
          pickUpLocation: null,
        });
      } else {
        this.updateMapData();
      }
    },

    updateMapData() {
      this.mapManager.removeCityMarkers();
      this.updateRoute();
      this.updateZone();
      this.createMarkerCluster();
    },

    updateRoute() {
      const route = this.mapManager.getRoute();
      const lastMarkerSelected = this.mapManager.getLastMarkerSelected();

      if (!route && lastMarkerSelected) {
        this.createRouteFromMarker(lastMarkerSelected);
      }
    },

    createRouteFromMarker(fromMarker) {
      const geocompleteMarker = this.mapManager.getGeocompleteMarker();

      const isPinVisible = geocompleteMarker?.getVisible();

      if (!fromMarker.getVisible() || !fromMarker.getMap()) {
        this.mapManager.showMarker(fromMarker);
      }

      if (geocompleteMarker
        && isPinVisible
        && this.isPinInSameCity({ geocompleteMarker, isPinVisible })
      ) {
        this.mapManager.drawRoute(fromMarker.id, geocompleteMarker.id);
      }
    },

    updateZone() {
      const markerZone = this.mapManager.getMarkerZone();

      if (markerZone && !markerZone.getVisible()) {
        this.mapManager.showMarker(markerZone);
        this.$nextTick(() => {
          google.maps.event.trigger(markerZone, 'click');
        });
      } else {
        this.mapManager.showZone();
      }
    },

    createMarkerCluster() {
      const markerCluster = this.mapManager.getMarkerCluster();

      if (!markerCluster && this.mapManager.getLocationMarkers().length) {
        this.mapManager.createMarkerCluster();
      }
    },

    async onChangeInputGeocomplete({ lat, lng, placeResult }) {
      if (!(lat && lng)) {
        return;
      }

      try {
        // Is there any route created?
        // Yes? We have to hide it
        // No? Doesn't matter, it does not throw any exception
        this.mapManager.hideRoute();

        // Get the Geocomplete Marker
        let geocompleteMarker = this.mapManager.getGeocompleteMarker();
        // Is there a Geocomplete Marker in the Map?
        // No? We have to create one
        // Yes? Doesn't matter, later we will assign a new Lat and Lng
        if (isNil(geocompleteMarker)) {
          geocompleteMarker = this.mapManager.createGeocompleteMarker({
            lat,
            lng,
          });

          this.mapManager.addMarker(geocompleteMarker);
        }

        this.mapManager.showMarker(geocompleteMarker.id);

        // Is searched place in some city of our system?
        const isIn = await this.isLocationInSomeCity({ lat, lng });

        this.selectedLocation = get(placeResult, 'uuid', null);

        // No!
        // We should hide the vehicles, it could be confuse
        // for the user
        if (!isIn) {
          // Set the current city
          // Later we will call Availability api and it will hide
          // the vehicle list
          this.setCurrentCity(this.getUnknownCity(lat, lng));
          // Maybe Map could be waiting to know some geolocation
          // of the user, it could be geolocation marker or user marker.
          // When user searches a city, map has to be unlocked
          this.setWaitingGeolocation(false);

          // Set the center of the map in searched place
          this.mapManager.setCenterMap(lat, lng);

          // We wil show the City icons,
          // so the user will know where he can make a booking
          this.mapManager.zoomOutUntilShowCityIcons();

          this.$nextTick(async () => {
            try {
              this.refreshAvailability(true);
              this.setBooking({
                pickUpLocation: null,
              });
            } catch ({ message }) {
              this.notifyError({
                text: message,
                textAction: this.$t('modal.common.ok'),
              });
            }
          });
        } else {
          // WoOoh!!
          // He is searching one place of our cities

          // Set the center of the map in searched place
          this.mapManager.setCenterMap(lat, lng);

          // We will do zoom in in the map
          // to see better the close locations
          this.mapManager.setCenterMapAndZoom(lat, lng);
        }

        // Change the Lat and Lng of Geolocation Marker.
        this.mapManager.changeLocationMarker('geocomplete', lat, lng);
      } catch ({ message }) {
        logger.message('Error catched on change input geocomplete', LOG_TYPE.danger);
        this.notifyError({ text: message });
      }
    },

    showModal(component, options = {}) {
      const {
        module,
        props = {},
        listeners = {},
      } = component || {};

      this.trackModalView({ modalName: GTM_MODAL_EVENTS.validationStatusModal });

      if (!module) {
        logger.message('Module not found in showModal', LOG_TYPE.warn);
        return;
      }

      this.modals.setup = {
        module,
        props: {
          isOpen: true,
          ...props,
        },
        listeners: {
          'update:is-open': value => {
            this.modals.setup.props.isOpen = value;
            this.trackPageView();
            invoke(options, 'toggleBtnLoading');
          },
          ...listeners,
        },
      };
    },

    getAgeOrDrivingLicenseValidationProps(operator, toggleBtnLoading, errorKey) {
      const title = this.$t('user_validations.create_booking.generic_titles.can_not_use_fleet', { operator: this.appName });
      const errorMessageKey = `user_validations.create_booking.without_document.errors.${errorKey}.description`;
      const errorMessageParams = validationErrorMessageParams(errorKey, get(operator, 'configuration'));
      const description = this.$t(errorMessageKey, {
        ...errorMessageParams,
        operator: this.appName,
      });

      return {
        ...genericUserBlockArgs(this.$t),
        title,
        description,
        isOpen: true,
        primaryCallToAction: () => {
          this.modals.setup.props.isOpen = false;
          if (isFunction(toggleBtnLoading)) {
            toggleBtnLoading();
          }
        },
      };
    },

    async onClickValidateUser(params) {
      const {
        location = {},
        toggleBtnLoading,
      } = params;

      const genericModalOptions = {
        toggleBtnLoading,
      };

      const startBookingCreation = () => {
        this.onClickCreateReservation(params);
      };

      this.removeAllNotifications();

      this.trackCartEvent({
        bookingData: this.datetimepickerManager,
        city: this.getCurrentCity,
        currency: this.getCurrentOperatorCurrency,
        location: mapLocationData(location),
        vehicles: [get(params, 'vehicle')],
        eventName: GTM_EVENTS.addToCart,
      });

      if (this.isBookingOnBehalf) {
        startBookingCreation();
        return;
      }

      const operatorUuid = get(location, 'cs_operator_uuid');
      const operator = await getOperator(operatorUuid);

      await Promise.all([
        fetchRequiredDocuments({
          csOperatorUuid: operatorUuid,
        }),
        fetchUserValidations({
          cityUuid: get(this, 'getCurrentCity.uuid'),
          bookingType: this.currentBookingType.code,
        }),
      ]);

      const validations = [
        {
          status: USER_VALIDATION_STATUS.aboutToExpire,
          action: () => {
            const component = {
              module: CorporateValidationComponent,
              props: {
                selectedLocation: location,
              },
              listeners: {
                'update:is-open': value => {
                  this.modals.setup.props.isOpen = value;
                  startBookingCreation();
                },
              },
            };

            this.showModal(component);
          },
        },
        {
          status: USER_VALIDATION_STATUS.ageUnderRequirements,
          action: () => {
            const component = {
              module: FeedbackModalComponent,
              props: this.getAgeOrDrivingLicenseValidationProps(operator, toggleBtnLoading, 'age_under_requirements'),
              listeners: {
                input: () => {
                  if (isFunction(toggleBtnLoading)) {
                    toggleBtnLoading();
                  }
                },
              },
            };

            this.showModal(component);
          },
        },
        {
          status: USER_VALIDATION_STATUS.ageNotAvailable,
          action: () => {
            const component = {
              module: FeedbackModalComponent,
              props: {
                ...genericErrorArgs(this.$t),
                isOpen: true,
                primaryCallToAction: () => {
                  this.modals.setup.props.isOpen = false;
                  if (isFunction(toggleBtnLoading)) {
                    toggleBtnLoading();
                  }
                },
              },
            };

            this.showModal(component, genericModalOptions);
          },
        },
        {
          status: USER_VALIDATION_STATUS.novelDrivingLicense,
          action: () => {
            const component = {
              module: FeedbackModalComponent,
              props: this.getAgeOrDrivingLicenseValidationProps(operator, toggleBtnLoading, 'novel_driving_license'),
              listeners: {
                input: () => {
                  if (isFunction(toggleBtnLoading)) {
                    toggleBtnLoading();
                  }
                },
              },
            };

            this.showModal(component);
          },
        },
        {
          status: USER_VALIDATION_STATUS.incomplete,
          action: () => {
            const module = (this.areRequiredDocumentsForCustomers && !this.hasDedicatedFleet && FeedbackModalComponent)
              || (this.areRequiredDocumentsForCustomers && this.hasDedicatedFleet && CustomerValidationComponent)
              || (this.areRequiredDocumentsForCorporates && CorporateValidationComponent)
              || null;

            const component = {
              module,
              props: {
                title: this.$t('settings.no_desktop'),
                description: this.$t('settings.driving_license.no_desktop_description'),
                image: downloadAppImage,
                selectedLocation: location,
                primaryCallToActionText: this.$t('buttons.got_it'),
                primaryCallToAction: () => {
                  this.modals.setup.props.isOpen = false;
                  if (isFunction(toggleBtnLoading)) {
                    toggleBtnLoading();
                  }
                },
                onValidationCompleted: () => {
                  if (this.hasCompletedDrivingLicense) {
                    return;
                  }

                  const successComponent = {
                    module: WaitingForValidationModalComponent,
                    props: {
                      title: this.$t('BookingView.WaitingForValidationModalComponent.title_validation_completed'),
                      operator,
                    },
                  };

                  this.showModal(successComponent);
                },
              },
            };

            this.showModal(component, genericModalOptions);
          },
        },
        {
          status: USER_VALIDATION_STATUS.expired,
          action: () => {
            const component = {
              module: CorporateValidationComponent,
              props: {
                selectedLocation: location,
              },
            };

            this.showModal(component, genericModalOptions);
          },
        },
        {
          status: USER_VALIDATION_STATUS.pending,
          action: () => {
            const component = {
              module: WaitingForValidationModalComponent,
              props: {
                title: this.$t('BookingView.WaitingForValidationModalComponent.title_still_waiting'),
                operator,
              },
            };

            this.showModal(component, genericModalOptions);
          },
        },
        {
          status: USER_VALIDATION_STATUS.extraDocumentsNeeded,
          action: () => {
            const component = {
              module: WaitingForValidationModalComponent,
              props: {
                title: this.$t('BookingView.WaitingForValidationModalComponent.title_still_waiting'),
                operator,
              },
            };

            this.showModal(component, genericModalOptions);
          },
        },
        {
          status: USER_VALIDATION_STATUS.complete,
          action: () => {
            startBookingCreation();
          },
        },
      ];

      const matchingValidation = validations.find(validation => this.isValidStatusByType({
        bookingType: this.currentBookingType.code,
        csOperatorUuid: operatorUuid,
        type: validation.status,
      }));

      if (!matchingValidation) {
        if (isFunction(toggleBtnLoading)) {
          toggleBtnLoading();
        }
        return;
      }

      matchingValidation.action();
    },

    async onClickCreateReservation(params) {
      const {
        vehicle,
        location = {},
        vehicleCategory,
        force = false,
        toggleBtnLoading,
        isAvailabilitySuggestionsEnabled,
      } = params;

      const operatorUuid = get(location, 'cs_operator_uuid');
      const operator = await getOperator(operatorUuid);

      if (this.userData.hasPendingLogbook() && this.userData.isLogbookLast()) {
        if (isFunction(toggleBtnLoading)) {
          toggleBtnLoading();
        }
        this.openLogbookModal(ALERT_TYPES.error);
        return;
      }

      // User tries to book in other country / cs operator
      // or the current documents signed are outdated, user has to
      // accept the latest version of them
      if (!force) {
        const errorModalSetup = {
          isOpen: true,
          props: {
            ...genericErrorArgs(this.$t),
            primaryCallToAction: () => {
              toggleBtnLoading();
              this.closeModal();
            },
          },
        };

        const hasMissingLegalDocuments = !!(await this.getMissingLegalDocuments({
          operatorUuid: location.cs_operator_uuid,
        }));

        if (!hasMissingLegalDocuments) {
          this.modalSetup = errorModalSetup;
          return;
        }

        if (size(this.missingLegalDocuments)) {
          this.modals.missingLegalDocuments = {
            isOpen: true,
            props: {
              documents: this.missingLegalDocuments,
              onCancel: () => {
                this.modals.missingLegalDocuments.isOpen = false;
                if (isFunction(toggleBtnLoading)) {
                  toggleBtnLoading();
                }
              },
              onAccept: async ({ acceptedUuids }) => {
                const areAccepted = !!(await this.acceptLegalDocuments({
                  legalDocumentsUuid: acceptedUuids,
                }));

                this.modals.missingLegalDocuments.isOpen = false;

                if (!areAccepted) {
                  this.modalSetup = errorModalSetup;
                  return;
                }

                this.onClickValidateUser({
                  ...params,
                  force: true,
                });
              },
            },
          };

          return;
        }
      }

      if (!this.isProfileCostAllocationSet && !this.isBookingBehalfEmployee) {
        if (isFunction(toggleBtnLoading)) {
          toggleBtnLoading();
        }

        this.notifyError({
          text: this.$t('modal.cost_allocation.error_notification_message'),
          textAction: this.$t('buttons.complete_now'),
          action: () => this.goToCostAllocations(),
        });
        return;
      }

      const userProfileUuid = this.isBookingBehalfEmployee
        ? this.employeeDriver.business_profile_uuid
        : this.currentProfile.getUUID();
      const bookingType = this.currentBookingType.getCode();

      await this.fetchBookingRules({
        bookingType,
        userProfileUuid,
        vehicleUuid: get(vehicle, 'uuid'),
        vehicleCategoryUuid: get(vehicleCategory, 'uuid'),
        locationUuid: vehicleCategory ? get(location, 'uuid') : undefined,
      });

      const bookingTimer = toBookingTimer(this.datetimepickerManager);

      const {
        minimumBookingDuration,
        maximumBookingDuration,
      } = this.bookingRulesData;

      const isMinLessRestrictive = minimumBookingDuration < get(bookingTimer, 'rules.minDuration');
      const isMaxLessRestrictive = maximumBookingDuration > get(bookingTimer, 'rules.maxDuration');

      const isLessRestrictive = isMinLessRestrictive || isMaxLessRestrictive;

      if (!this.isBookingBetweenRules(bookingTimer) || isLessRestrictive) {
        this.bookingSummary.params = params;

        const operatorConfig = cloneDeep(operator);
        operatorConfig.setDuration(minimumBookingDuration, maximumBookingDuration);

        const start = this.datetimepickerManager.getStart();
        const end = this.datetimepickerManager.getEnd();
        const datetimepickerManager = this.getBookingRulesByCurrentBookingType(assign(
          operatorConfig,
          {
            start: moment(start),
            end: moment(end),
          },
        ));

        const component = {
          module: ChangeTimeSlotModalComponent,
          props: {
            title: isLessRestrictive
              ? this.$t('BookingView.ChangeTimeSlotModalComponent.less_restrictive_title')
              : this.$t('BookingView.ChangeTimeSlotModalComponent.selected_dates_title'),
            message: isLessRestrictive
              ? this.$t('BookingView.ChangeTimeSlotModalComponent.less_restrictive_reason')
              : this.$t('BookingView.ChangeTimeSlotModalComponent.selected_dates_reason'),
            availabilityParams: {
              profile_uuid: this.getProfileUuidSelected,
              city_uuid: get(this, 'getCurrentCity.uuid'),
              booking_type: this.currentBookingType.getCode(),
              gps_lat: invoke(this, 'getCurrentCity.getLatitude'),
              gps_lng: invoke(this, 'getCurrentCity.getLongitude'),
              radius: 1000000,
            },
            bookingRulesData: this.bookingRulesData,
            datetimepickerManager,
            t: this.$t,
            vehicleUuid: get(vehicle, 'uuid'),
            operatorConfig,
          },
          listeners: {
            'confirm-slot': this.goBookingSummaryWithNewSlot,
          },
        };

        this.showModal(component, { toggleBtnLoading });
        return;
      }

      if (isAvailabilitySuggestionsEnabled) {
        toggleBtnLoading();

        this.trackSegment({
          name: SEGMENT_EVENTS_NAME.lookForSuggestions,
          data: {
            locationOperatorUuid: get(location, 'cs_operator_uuid'),
            locationUuid: get(location, 'uuid'),
            selectedBookingDateTimeStart: this.datetimepickerManager.getStart().format(DATE_FORMAT.filter),
            selectedBookingDateTimeEnd: this.datetimepickerManager.getEnd().format(DATE_FORMAT.filter),
          },
        });
        this.modals.availabilitySuggestions.isOpen = true;
        this.modals.availabilitySuggestions.props = {
          start: this.datetimepickerManager.getStart().format(DATE_FORMAT.filter),
          end: this.datetimepickerManager.getEnd().format(DATE_FORMAT.filter),
          bookingType: get(this, 'currentBookingType.code'),
          locationUuid: get(location, 'uuid'),
          locationCSOperator: get(location, 'cs_operator_uuid'),
          categoryUuid: get(vehicleCategory, 'uuid'),
          onConfirmFn: ({ start, end }) => {
            const startDate = moment(start);
            const endDate = moment(end);
            this.setCurrentStartDateTime(startDate);
            this.setCurrentEndDateTime(endDate);
            this.goBookingSummary(params, startDate, endDate);
          },
        };
        return;
      }
      this.goBookingSummary(params);
    },

    closeChangeTimeSlotModal() {
      this.modals.changeTimeSlot.isOpen = false;
    },

    goToCostAllocations() {
      this.$router.push({ name: businessProfileRoutesNames.costAllocations });
    },

    goBookingSummaryWithNewSlot(bookingSummaryParams = {}) {
      const {
        start,
        end,
        startAvailability,
        endAvailability,
      } = bookingSummaryParams;

      this.goBookingSummary(this.bookingSummary.params, start, end, startAvailability, endAvailability);
    },

    async goBookingSummary({
      vehicle,
      location,
      vehicleCategory,
      toggleBtnLoading = noop,
    }, startFromVehicle, endFromVehicle, startAvailabilityVehicle, endAvailabilityVehicle) {
      this.bookingSummary.params = null;
      const reservation = {
        vehicle,
        vehicleCategory,
        location,
        booking_type: this.currentBookingType.getCode(),
        start: this.dateFormat(startFromVehicle || this.currentStartDateTime),
        end: this.dateFormat(endFromVehicle || this.currentEndDateTime),
        start_availability: this.datetimepickerManager.start,
        end_availability: this.datetimepickerManager.end,
        // When user is the same that the driver
        // we can pick his data from Store. If not, from Algolia object
        user_uuid: this.isBookingBehalfEmployee
          ? this.employeeDriver.uuid
          : this.userData.getUUID(),
        user_profile_uuid: this.isBookingBehalfEmployee
          ? this.employeeDriver.business_profile_uuid
          : this.currentProfile.getUUID(),
        ...this.availabilityRange.value,
      };

      if (startAvailabilityVehicle) {
        reservation.start_availability = startAvailabilityVehicle;
      }
      if (endAvailabilityVehicle) {
        reservation.end_availability = endAvailabilityVehicle;
      }

      try {
        await this.preparateBookingsummary(reservation);

        this.setBookingStart({ started: true });

        const bookingHash = this.createHashUsedInBooking();

        this.trackSegment({
          name: SEGMENT_EVENTS.RESERVATION_SUMMARY,
          data: {
            [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: reservation.booking_type,
            [SEGMENT_PARAM_NAMES.SUCCESSFUL_RESERVATION_SUMMARY]: true,
            [SEGMENT_PARAM_NAMES.UNSUCCESSFUL_RESERVATION_SUMMARY_REASON]: null,
          },
        });

        this.removeAllVehicleUsers();

        this.setBookingCostAllocations([]);

        this.$router.push({
          name: BookingRoutesNames.bookingSummary,
          params: {
            hash: bookingHash,
          },
        });
      } catch (error) {
        logger.message('Error on go to booking summary', LOG_TYPE.error);
        toggleBtnLoading();

        const errorMessage = get(error, 'response.data.message', error.message);

        this.notifyError({
          text: errorMessage,
        });
        this.trackSegment({
          name: SEGMENT_EVENTS.RESERVATION_SUMMARY,
          data: {
            [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: reservation.booking_type,
            [SEGMENT_PARAM_NAMES.SUCCESSFUL_RESERVATION_SUMMARY]: false,
            [SEGMENT_PARAM_NAMES.UNSUCCESSFUL_RESERVATION_SUMMARY_REASON]: errorMessage,
          },
        });
      }
    },

    async onChangeProfile() {
      await this.checkBookingTypesPermissions();
      this.setDefaultEmployeeDriver();
      this.setHeightInVehicleList(this.hasNotification);

      /**
         * When chaning the profile having the map visible,
         * we need to remove the actual cities from the map
         * and showing the correct ones,
         * also we have to fit the cities on the map again when:
         * - user doesn't has the geolocation activated
         * - OR user has geolocation activated but the place that is looking
         * doesn't has availability
         */
      this.mapManager.removeCityMarkers();
      await this.initMapFlow();
      this.refreshAvailability(true);

      this.showNotificationsOrRoadblock();
    },

    async onBookingTypeChange(bookingType) {
      this.trackSegment({
        name: SEGMENT_EVENTS.BOOKING_TYPE_SELECTED,
        data: {
          booking_type_selected: bookingType,
        },
      });

      await this.setCurrentBookingType(bookingType);

      const operatorConfig = cloneDeep(this.activeOperatorConfig);
      const start = this.datetimepickerManager.getStart();
      const end = this.datetimepickerManager.getEnd();

      this.datetimepickerManager
          = this.getBookingRulesByCurrentBookingType(assign(
          operatorConfig,
          {
            start: moment(start),
            end: moment(end),
          },
        ));

      this.setCurrentStartDateTime(this.datetimepickerManager.getStart());
      this.setCurrentEndDateTime(this.datetimepickerManager.getEnd());

      this.refreshAvailability();
    },

    async refreshAvailability(force = false) {
      this.setAvailabilityResponse(null);

      this.$nextTick(async () => {
        try {
          this.getUserAvailability(force);
        } catch ({ message }) {
          this.notifyError({
            text: message,
            textAction: this.$t('modal.common.ok'),
          });
        }
      });
    },

    onDatetimepickerChange(manager) {
      this.datetimepickerManager.setStart(manager.start);
      this.datetimepickerManager.setEnd(manager.end);

      const numHoursDiff = moment.duration(
        manager.end.diff(manager.start),
      ).asHours();

      this.trackSegment({
        name: SEGMENT_EVENTS.FILTER_DATE_SELECTED,
        data: {
          [SEGMENT_PARAM_NAMES.NUM_HOURS]: numHoursDiff,
          [SEGMENT_PARAM_NAMES.COMPONENT_VIEW]: SEGMENT_ENUMERATE_VALUES.COMPONENT_VIEW.MAP,
        },
      });

      // DOC:  Issue with CoBa --> [CSS1-429] . User has no tariff but company yes.
      // DOC: User tariff expiry date can be undefined,
      // it means it never expired (ex: free fee tariff)

      const bookingDateOutOfTariff = this.tariffExpiryDate && manager.end.isAfter(moment(this.tariffExpiryDate));
      // TODO: REMOVE FEATURE FLAG [CSS1-773]
      if (
        !this.$featureFlag.flags.fixCobaTariffIssue
          && !this.currentProfile.isBusinessProfile()
          && bookingDateOutOfTariff
      ) {
        this.datetimepickerManager.setStart(moment(this.oldStartAvailabilityValue.value));
        this.datetimepickerManager.setEnd(moment(this.oldEndAvailabilityValue.value));
        this.isTariffWillExpireModalOpen = true;
      } else {
        this.setCurrentStartDateTime(manager.start);
        this.setCurrentEndDateTime(manager.end);

        this.refreshAvailability();
      }
    },

    async onMapLoaded() {
      const google = googleMapsLoader.getInstance();

      this.isCenteredOnCurrentPosition = this.getUserGeolocation().isActive();

      // We need this event binded to show the city icons if they are necessary
      // when user marker is dropped in Map
      this.eventHandler.$on(this.events.ZOOM_MAP_CHANGING, this.onZoomMapChanging);

      await this.initMapFlow();

      // Set empty list message by default
      // If User geolocation is not active
      // we will set other message
      // TODO: THIS CODE IS DONE IN MIXIN/AVAILABILITY
      // this.setEmptyMessageLocations(this.$t('views.home.empty_list_title'));

      this.addBeforeAvailabilityAction(new AvailabilityAction(() => {
        this.mapManager.hideAllMarkers();

        this.mapManager.hideRoute();

        this.mapManager.hideZone();

        if (this.mapManager.getMarkerZone()) {
          this.mapManager.hideMarker(this.mapManager.getMarkerZone());
        }

        this.mapManager.setMarkerZone(null);

        this.mapManager.closeLastInfoWindows();

        this.mapManager.removeAllParkingMarkersFromList();

        this.mapManager.removeMarkerCluster();

        this.mapManager.setLastMarkerSelected(null);
      }));

      this.addAfterAvailabilityAction(new AvailabilityAction(() => {
        const locations = this.locationsAvailables;

        if (size(locations) > 0) {
          locations.forEach(loc => {
            const svgType = loc.is_geofence
              ? MARKER_STATES.geofence
              : MARKER_STATES.default;

            const vehiclesAvailable = loc.show_vehicle_availability && !isUndefined(loc.vehicles_available)
              ? loc.vehicles_available
              : null;

            const svg = this.mapManager.constructor.getSvgMarker(vehiclesAvailable, svgType, loc.open, loc.one_way_allowed);
            const iconUrl = this.mapManager.constructor.getSvgIconUrl(svg);

            let extraOptions = {};

            if (loc.is_geofence) {
              extraOptions = {
                defaultIcon: iconUrl,
              };
            }

            const marker = this.mapManager.createMarker(
              {
                id: `location_${loc.uuid}`,
                lat: loc.gps_lat,
                long: loc.gps_lng,
                title: loc.name,
                icon: iconUrl,
                data: loc,
                infoWindows: {
                  location: this.mapManager.constructor.createInfoWindow({ title: loc.name }),
                  time: this.mapManager.constructor.createInfoWindowTime(0),
                },
                ...extraOptions,
              },
            );

            const onClickLocationInfo = () => {
              this.modals.locationsDetail = {
                isOpen: true,
                parentLocation: loc,
                mapManager: this.mapManager,
              };
              this.trackModalView({ modalName: GTM_MODAL_EVENTS.locationInfo });
            };

            google.maps.event.addListener(marker, 'click', (shouldScrollToLocation = true) => {
              if (!this.isEstimatedTripConfigured) {
                this.isDistanceFilterModalOpen = true;
              }

              // When users clicks a marker
              // We have to close all Infowindows
              this.mapManager.closeLastInfoWindows();
              // Hide the route
              this.mapManager.hideRoute();
              // Hide the route and show the marker
              this.mapManager.hideZone();

              this.$nextTick(async () => {
                // Show all markers
                // (because when a marker zone is clicked,
                // all parkings has to be removed)
                this.mapManager.showAllLocationMarkers();
                // Create a cluster if it's not created
                this.mapManager.createMarkerCluster();

                // Is the location clicked a zone?
                // Yes! we will create a new zone and show it,
                // hide the rest of the markers / parkings
                // and the marker cluster.
                // If not, doesn't matter
                if (marker.data.is_geofence) {
                  this.mapManager.createZone(marker.data.coordinates.data);
                  this.mapManager.setMarkerZone(marker);
                  this.$nextTick(() => {
                    this.mapManager.showZone();
                  });
                }

                this.mapManager.setLastMarkerSelected(marker);

                this.mapManager.setLastInfoWindowOpened(marker.infoWindows.location);

                google.maps.event.addListener(marker.infoWindows.location, 'domready', () => {
                  const infoWindow = document.querySelector('.MapInfoWindow');
                  if (!infoWindow) {
                    return;
                  }
                  const container = get(infoWindow, 'offsetParent.parentNode');

                  if (!container) {
                    return;
                  }

                  container.classList.add('MapInfoWindow__wrapper');
                  container.addEventListener('click', onClickLocationInfo);
                });

                marker.infoWindows.location.open(this.mapManager.getMap(), marker);

                let promiseDistance = null;

                const geocompleteMarker = this.mapManager.getGeocompleteMarker();
                const userMarker = this.mapManager.getUserMarker();

                // When marker clicked is in the same city of user
                // (with geolocation activated), we have to create
                // the route and said how may time he has to spent.
                const isUserVisible = userMarker && userMarker.getVisible();
                const userCity = await this.isLocationInSomeCity({
                  lat: this.getUserGeolocation().getLatitude(),
                  lng: this.getUserGeolocation().getLongitude(),
                });
                const isUserInSameCity = get(this, 'currentCity.uuid') === get(userCity, 'uuid');
                const isPinVisible = geocompleteMarker && geocompleteMarker.getVisible();

                // Route will be create from Location to geocomplete marker by default
                // when geocomplete marker does not exist, this route will be create to user marker
                // and if user marker does not exist, Map will be centered in the location position
                if (isPinVisible && await this.isPinInSameCity({ geocompleteMarker, isPinVisible })) {
                  // Draw route
                  this.mapManager.drawRoute(geocompleteMarker.id, marker.id);

                  // Get how many time there is between 2 points
                  promiseDistance = MapManager.howManyTime(geocompleteMarker, marker);
                } else if (isUserVisible && isUserInSameCity) {
                  this.mapManager.drawRoute(userMarker.id, marker.id);

                  // Get how many time there is between 2 points
                  promiseDistance = MapManager.howManyTime(userMarker, marker);
                } else {
                  // Distance can not be calculated because we need a destination of the route
                  promiseDistance = Promise.resolve({});

                  this.mapManager.setCenterMap(
                    marker.getPosition().lat(),
                    marker.getPosition().lng(),
                  );
                }

                // When distance is calculated
                await promiseDistance.then(({ rows }) => {
                  if (rows) {
                    const [row] = rows;
                    const [element] = row.elements;
                    const minutes = element.duration.text;

                    // Create a temporal InfoWindow to get his content
                    // but really, the InfoWindow used is that created previously
                    const i = this.mapManager.constructor.createInfoWindowTime(minutes);
                    marker.infoWindows.time.setContent(i.getContent());

                    this.mapManager.setLastTimeInfoWindowOpened(marker.infoWindows.time);

                    google.maps.event.addListener(marker.infoWindows.time, 'domready', () => {
                      const infoWindow = document.querySelector('.MapTimeInfoWindow');
                      if (!infoWindow) {
                        return;
                      }

                      const addClass = get(infoWindow, 'offsetParent.container.parentNode.classList.add');
                      if (isFunction(addClass)) {
                        addClass('MapInfoWindow__wrapper');
                      }
                    });

                    marker.infoWindows.time.open(this.mapManager.getMap(), marker);
                  }
                });

                // One way interaction when a marker is clicked
                const uuid = get(marker, 'data.uuid');
                const location = find(this.operatorLocations, { uuid });
                this.setBooking({
                  pickUpLocation: location,
                });

                if (shouldScrollToLocation) {
                  this.eventHandler.$emit(this.events.MARKER_SELECTED, marker);
                }
              });
            });

            this.mapManager.addMarker(marker);

            this.mapManager.showMarker(marker.id);
          });

          const firstLocation = head(locations);
          this.mapManager.setCenterMapAndZoom(
            get(firstLocation, 'gps_lat'),
            get(firstLocation, 'gps_lng'),
          );
        } else if (!this.isSomeFilterApplied && this.getUserGeolocation().isActive()) {
          this.mapManager.zoomOutUntilShowCityIcons();
        }

        // Only we will show the user marker when
        // he has geolocation in the browser activated
        if (this.getUserGeolocation().isActive()) {
          this.mapManager.showMarker(this.mapManager.getUserMarker());
        }

        // Add a marker clusterer to manage the markers.
        this.mapManager.createMarkerCluster();

        this.$nextTick(() => {
          if (this.mapManager.isMapShowingCityIcons() && locations?.length > 0) {
            this.mapManager.setCenterMapAndZoom(
              geolocationManager.getLatitude(),
              geolocationManager.getLongitude(),
            );
          }
        });

        // TODO: IMPROVE IT
        // This code is not used because when we returns back from Booking page,
        // default city could be not the same of the city selected previously.
        // Whe should improve this feature
        const { reservation } = this.$store.state.Booking;
        if (reservation.location && reservation.location.uuid) {
          const list = this.mapManager.getMarkerList();
          const marker = list[`location_${reservation.location.uuid}`];
          if (marker) {
            this.$nextTick(() => {
              google.maps.event.trigger(marker, 'click');
            });
          }
        }
      }));

      this.eventHandler.$on(this.events.MAP_BOUNCING, this.onMapBouncing);
      this.eventHandler.$on(this.events.SHOW_BOOKING_SUMMARY, this.onClickBookCar);
      this.eventHandler.$on(this.events.GO_BOOKING_CONFIRM, this.onClickValidateUser);

      if (this.currentProfile.isBusinessProfile() || this.userHasTariff) {
        try {
          this.refreshAvailability(true);
        } catch (error) {
          logger.message('Error catched on map loaded', LOG_TYPE.danger);
          this.notifyError({
            text: get(error, 'response.data.message', 'Unknown error'),
            textAction: this.$t('modal.common.ok'),
          });
        }
      }
      this.isMapLoaded = true;
    },

    async centerMapInUserCurrentPosition() {
      // Is geolocation active?
      const geolocationIsActive = geolocationManager.isActive();

      // Geolocation is active!
      // And currentPosition is false
      if (geolocationIsActive && !this.isCenteredOnCurrentPosition) {
        // In this case, we have to set the center map in the
        // user geolocation position and the component has to be activated
        const lat = geolocationManager.getLatitude();
        const lng = geolocationManager.getLongitude();

        // If user is in one of our cities,
        // we will do zoom in, if not, zoom out
        const isIn = await this.isLocationInSomeCity({ lat, lng });
        if (isIn) {
          this.mapManager.setCenterMapAndZoom(lat, lng);
        } else {
          this.mapManager.setCenterMap(lat, lng);
          this.mapManager.zoomOutUntilShowCityIcons();
        }

        // Is there a route created?
        // we have to hide it
        if (this.mapManager.getRoute()) {
          this.mapManager.hideRoute();
        }

        // PD: In this point, it's impossible to create
        // a new route from Location to user geolocation position
        // because when we created the city icons, we remove
        // the reference of the Last marker selected.
        //
        // This code is used when current position of the user
        // is in one of our cities and the geolocation marker too.
        // Once cities icons are showed, this functionality
        // is useless
        const fromMarker = this.mapManager.getLastMarkerSelected();
        const toMarker = this.mapManager.getUserMarker();
        if (fromMarker && toMarker) {
          this.mapManager.drawRoute(fromMarker.id, toMarker.id);
        }

        // Finally, we will hide the Geocomplete marker
        // because we will not use more
        const geocompleteMarker = this.mapManager.getGeocompleteMarker();
        if (geocompleteMarker) {
          this.mapManager.hideMarker(geocompleteMarker);
        }

        this.isCenteredOnCurrentPosition = true;
      }

      // Implement again when Motion will be updated
      // this.$refs.geocompleteInput.clear();
    },

    onZoomMapChangingToShowCitiesIcon() {
      // When we are removing the Cluster of markers
      // also we are doing:
      // - Remove the reference to Cluster in MapManager
      // - Remove the reference to Last Marker selected in MapManager
      // - Hide all Location markers (user marker and geolocation marker will continue in the Map)
      // - And close the InfoWindows
      this.mapManager.removeMarkerCluster();

      // If there is a zone showed,
      // we have to hide it. Later, we will show again
      // when zoom map will be higher
      if (this.mapManager.getZone() && this.mapManager.getMarkerZone()) {
        this.mapManager.getZone().setVisible(false);
        this.mapManager.closeLastInfoWindows();
      }

      this.createCityMarkers();
    },

    createCityMarkers() {
      const google = googleMapsLoader.getInstance();

      // Create a marker for each Ubeeqo City
      const cityCollectionByProfile = this.getCityCollectionByProfile(get(this, 'currentProfile.uuid'));

      cityCollectionByProfile.forEach(city => {
        const marker = this.mapManager.createMarker({
          id: `city_${city.getUUID()}`,
          lat: city.getLatitude(),
          long: city.getLongitude(),
          icon: {
            url: mapMarkerSelector.cityMarkerIcon(),
            anchor: new google.maps.Point(49, 49),
          },
          animation: null,
          data: city,
        });

        // Remove click event if it exists
        google.maps.event.clearListeners(marker, 'click');

        // When city marker is clicked
        // Map center will be moved and it will do zoom in
        google.maps.event.addListener(marker, 'click', () => {
          const zoomLevel = this.mapManager.getZoomLevelWhenClickOnCityMarker();
          this.mapManager.setCenterMap(marker.getPosition().lat(), marker.getPosition().lng());
          this.mapManager.getMap().setZoom(zoomLevel);
        });

        // Add and show marker of the City
        this.mapManager.addAndShowMarker(marker);
      });
    },

    ...mapActions(UserDataNameSpace, {
      fetchUserData:
          UserDataActions.FETCH_USER_DATA,
    }),

    ...mapActions(OperatorLocationsNamespace, {
      fetchOperatorLocations: OperatorLocationsActions.fetchOperatorLocations,
    }),

    ...mapMutations(DrivingLicenseRoadblockNameSpace, {
      resetDLRoadblockState:
          DrivingLicenseRoadblockMutations.RESET_DATA,
    }),

    ...mapMutations(KeyCardRoadblockNameSpace, {
      resetKeyCardRoadblockState: KeyCardRoadblockMutations.resetData,
    }),

    ...mapMutations(
      VehicleUserNamespace,
      {
        removeAllVehicleUsers: VehicleUserMutations.removeAll,
      },
    ),

    ...mapMutations(
      BookingCostAllocationModule.NAMESPACE,
      {
        setBookingCostAllocations:
            CostAllocationDataModule.MUTATIONS.setCurrentCostAllcation,
      },
    ),

    ...mapMutations(BookingStore.nameSpace, {
      setBooking: BookingStore.MUTATIONS.SET_RESERVATION,
      setBookingSummary: BookingStore.MUTATIONS.SET_SUMMARY,
      setBookingStart: BookingStore.MUTATIONS.START_BOOKING,
      setEstimatedTripMileage: BookingStore.MUTATIONS.SET_ESTIMATED_TRIP_MILEAGE,
    }),

    ...mapActions(BookingModule.NAMESPACE, {
      fetchBookingRules: BookingModule.ACTIONS.fetchBookingRules,
    }),

    ...mapActions(
      BookingCostAllocationModule.NAMESPACE,
      {
        fetchExternalBooking:
            BookingCostAllocationModule.ACTIONS.fetchExternalBooking,
      },
    ),

    ...mapMutations(
      BookingCostAllocationModule.NAMESPACE,
      {
        setExternalBooking:
            BookingCostAllocationModule.MUTATIONS.setExternalBooking,
      },
    ),

    ...mapActions(UserValidationsModule.NAMESPACE, ['getUserCityValidations', 'fetchUserValidations']),

    // TODO: https://europcarmobility.atlassian.net/browse/CBF-948
    async openPreferredLocations() {
      await this.fetchFavouriteLocations(this.getUserData().uuid);
      this.modals.preferredLocations.isOpen = true;
    },

    // TODO: https://europcarmobility.atlassian.net/browse/CBF-948
    async updatePreferredLocations(preferredLocations) {
      this.modals.preferredLocations.isLoading = true;
      each(preferredLocations, async (newLocation, type) => {
        const newAdressStreet = get(newLocation, 'address_street');
        const currentAddressStreet = get(this.currentFavouriteLocations[type], 'address_street');

        if (newAdressStreet !== currentAddressStreet) {
          if (!isEmpty(currentAddressStreet)) {
            await this.deleteFavouriteLocation(this.currentFavouriteLocations[type].uuid);
            await this.fetchFavouriteLocations(this.getUserData().uuid);
          }

          if (!isEmpty(newAdressStreet)) {
            const payload = {
              userUuid: this.getUserData().uuid,
              location: {
                type,
                address: newAdressStreet,
                alias: type,
              },
            };
            try {
              await this.postFavouriteLocation(payload);

              this.notifySuccess({
                text: this.$t('modal.preferredLocations.changes_saved'),
                textAction: this.$t('modal.common.ok'),
              });

              await this.fetchFavouriteLocations(this.getUserData().uuid);
            } catch (error) {
              const text = parseApiErrorMessage(this.$t, this.$i18n, error);
              this.notifyError({ text });
              logger.message(`There was a problem posting favourite locations: ${error}`, LOG_TYPE.warning);
            }
          }
        }
      });

      this.modals.preferredLocations.isLoading = false;
      this.modals.preferredLocations.isOpen = false;
    },

    async initMapFlow() {
      // When geolocation is active, we have to get his
      // latitude and longitude and create an UserMarker there
      // This latitude and longitude is used to know in which city
      // is the user to do zoom in or zoom out.
      // In created() life cycle we assign the Latitude and Longitude
      // of the default city by default as user geolocation,
      // That is to say, we have to get always
      const userPosition = {
        lat: this.getUserGeolocation().getLatitude(),
        lng: this.getUserGeolocation().getLongitude(),
      };

      this.fetchFavouriteLocations(this.getUserData().uuid);

      // Check user position and center the map there
      const isUserCloseToOursCars = await this.isLocationInSomeCity(userPosition);
      await this.mapManager.setCenterMap(userPosition.lat, userPosition.lng);

      // Check the perfect conditions for our business.
      // When user is in one of our cities and he has the geolocation activated
      if (isUserCloseToOursCars && this.getUserGeolocation().isActive()) {
        // Yes!! User is in one of our cities
        // He can book a car soon
        await this.mapManager.setCenterMapAndZoom(userPosition.lat, userPosition.lng);

        // Get city where the user is
        const city = await this.getCityMostClose(userPosition.lat, userPosition.lng);

        this.setCurrentCity(city);

        await this.getUserCityValidations({ cityUuid: city.uuid, bookingType: this.currentBookingType.code });

        // When we know in which city is the user
        // we have to get the CS Operator of that city
        // and set as a visited
        const operator = await this.fetchCSOperator(city.getCSOperatorUUID());
        const isSameOperator = this.getVisitedCsOperator.getUUID() === operator.getUUID();
        if (isNil(this.getVisitedCsOperator) || !isSameOperator) {
          this.setAvailabilityFiltersDirectly([]);
          this.setOldAvailabilityFilters();
          this.setVisitedCsOperator(operator);

          this.datetimepickerManager
              = this.getBookingRulesByCurrentBookingType(Object.assign(
              this.activeOperatorConfig,
              {
                start: this.datetimepickerManager.getStart(),
              },
            ));
        }

        // Get locations by city to be showed
        // as interested points when user search in the map
        try {
          const locations = await this.fetchLocationsByCity(city.getUUID());
          this.setLocationCollection(locations);
        } catch (error) {
          logger.message('Error catched fetch city locations', LOG_TYPE.danger);
          this.setLocationCollection([]);
          this.notifyError({ text: error.message });
        }
      } else if (!isUserCloseToOursCars && this.getUserGeolocation().isActive()) {
        // No :(
        // He is too far. We will show all cities
        // and he could decide where he has to go
        // to book a car
        this.mapManager.zoomOutUntilShowCityIcons();

        // Set as current city a Fake city
        // It's used in Availability to know when
        // we have to do the Api call and when
        // we have to return an empty array
        // because user is not in one of our cities
        this.setCurrentCity(
          this.getUnknownCity(
            userPosition.lat,
            userPosition.lng,
          ),
        );
      } else if (!this.getUserGeolocation().isActive()) {
        // OMG! We don't know where is the user.
        // As fallback, in created() we set the view on all cities
        // We set the current city as unkown to make work the availability endpoint
        this.setCurrentCity(this.getUnknownCity());
        this.$nextTick(async () => {
          const bounds = result(this, 'getCitiesBounds');
          if (!bounds) {
            return;
          }
          const boundsCenter = bounds.getCenter();
          const center = {
            lat: result(boundsCenter, 'lat'),
            lng: result(boundsCenter, 'lng'),
          };
          const location = await this.isLocationInSomeCity(center);
          if (location) {
            this.mapManager.setCenterAndZoomMap(center, this.mapManager.zoomLevelToShowCityMarkers);
          } else {
            this.mapManager.getMap().fitBounds(bounds);
          }
        });

        if (this.firstTimeNotificationGeoLocation) {
          // We will show a warning message notifying
          // he has geolocation deactivated :(
          this.notifyWarning({ text: this.$t('views.home.empty_list_without_geolocation_title') });
          this.firstTimeNotificationGeoLocation = false;
        }
      }

      if (isNil(this.mapManager.getUserMarker())) {
        const userMarker = this.mapManager.createUserMarker({
          lat: this.getUserGeolocation().getLatitude(),
          lng: this.getUserGeolocation().getLongitude(),
        });

        this.mapManager.addMarker(userMarker);

        // Only we will show the user marker when
        // he has geolocation in the browser activated
        if (this.getUserGeolocation().isActive()) {
          this.mapManager.showMarker(userMarker.id);
        }
      }

      this.setCurrentStartDateTime(this.datetimepickerManager.getStart());
      this.setCurrentEndDateTime(this.datetimepickerManager.getEnd());
    },

    changeEmployee(employee) {
      try {
        if (employee) {
          this.setEmployeeProfile(employee);

          if (this.isMapLoaded) {
            this.refreshAvailability(true);
          }
        } else {
          this.employeeDriver = {
            uuid: get(this, 'userData.uuid'),
          };
          this.setEmployeeProfile({
            business_profile_uuid: this.currentProfile.getUUID(),
          });
        }
      } catch ({ message }) {
        this.notifyError({
          text: message,
          textAction: this.$t('modal.common.ok'),
        });
      }
    },

    onPickUpLocation(location) {
      this.setBooking({
        pickUpLocation: location,
      });

      if (location) {
        const markers = this.mapManager.getLocationMarkers();

        const marker = find(markers, { data: { uuid: location.uuid } });

        if (marker) {
          const google = googleMapsLoader.getInstance();
          google.maps.event.trigger(marker, 'click');
        } else if (location.gps_lat && location.gps_lng) {
          this.mapManager.setCenterMapAndZoom(location.gps_lat, location.gps_lng);
        }

        this.trackSegment({
          name: SEGMENT_EVENTS.CHOOSE_NEW_LOCATION_SEARCH,
          data: {
            new_value: location.name,
            search_type: 'station',
            ff_value: this.$featureFlag.flags.toggleSearchLocationTypes,
          },
        });
      }
    },

    getUserDataUsedInBooking() {
      return {
        ...this.userData,
        userGeolocation: {
          lat: get(this.mapManager, 'markerList.user.lat'),
          lng: get(this.mapManager, 'markerList.user.lng'),
        },
        addressGeolocation: {
          lat: get(this.mapManager, 'markerList.geocomplete.lat'),
          lng: get(this.mapManager, 'markerList.geocomplete.long'),
        },
      };
    },

    getDriverUsedInBooking() {
      // User can create bookings on behalf
      if (this.showEmployeeSelector) {
        // User/Company admin/Manager selects an employee to make a booking on behalf
        if (this.isBookingBehalfEmployee) {
          const { _highlightResult, ...data } = this.employeeDriver;
          return data;
        }

        return {
          uuid: this.userData.getUUID(),
          email: this.userData.email,
          user_first_name: this.userData.firstName,
          user_last_name: this.userData.lastName,
          personal_profile_uuid: get(this, 'hasPersonalProfile.uuid') || null,
          business_profile_uuid: get(this, 'hasBusinessProfile.uuid') || null,
        };
      }

      return null;
    },

    getLocationDataUsedInBooking(location) {
      return {
        uuid: get(location, 'uuid', FALLBACK_MESSAGE.dash),
        name: get(location, 'name', FALLBACK_MESSAGE.dash),
        address: get(location, 'name', FALLBACK_MESSAGE.dash),
        display_address: get(location, 'display_address', FALLBACK_MESSAGE.dash),
        is_open: get(location, 'open'),
        userDocuments: get(location, 'user_documents'),
        csOperatorRequirements: get(location, 'cs_operator_requirements'),
        lat: get(location, 'gps_lat'),
        lng: get(location, 'gps_lng'),
        isOpen: get(location, 'open'),
        one_way_allowed: get(location, 'one_way_allowed'),
      };
    },

    createHashUsedInBooking(extraData) {
      const bookingHash = this.newBookingHash();

      const data = this.getBookingEncoded(extraData);

      // Create a backup of the booking data
      window.sessionStorage.setItem(`booking_${bookingHash}`, data);

      return bookingHash;
    },

    async preparateBookingsummary(reservation) {
      const operatorUuid = get(reservation, 'location.cs_operator_uuid', this.activeOperatorConfig.uuid);
      const operator = await this.fetchCSOperator(operatorUuid);

      const isPreBooking = get(operator, 'configuration.uses_pre_booking');

      if (isPreBooking && !get(reservation, 'vehicleCategory')) {
        throw new Error('Please book a vehicle category for your booking request');
      }

      const bookingSummaryPayload = {
        ...get(reservation, 'vehicle.uuid') && { vehicle_uuid: get(reservation, 'vehicle.uuid') },
        ...get(reservation, 'vehicleCategory.uuid') && { category_uuid: get(reservation, 'vehicleCategory.uuid') },
        user_uuid: get(reservation, 'user_uuid'),
        location_uuid: get(reservation, 'location.uuid'),
        start: get(reservation, 'start'),
        end: get(reservation, 'end'),
        booking_type: get(reservation, 'booking_type'),
        user_profile_uuid: get(reservation, 'user_profile_uuid'),
      };

      const bookingSummary = await (!isPreBooking && external.booking.getBookingSummary(bookingSummaryPayload));

      if (bookingSummary) {
        this.setBookingSummary(bookingSummary);
        const vehicleUuid = get(bookingSummary, 'vehicle.uuid');
        if (vehicleUuid) {
          external.fleetVehicles.getVehicleDetails(vehicleUuid)
            .then(vehicleDetails => {
              this.setBooking({
                vehicle: {
                  ...get(bookingSummary, 'vehicle', {}),
                  ...(vehicleDetails || {}),
                  category: get(reservation, 'vehicle.category') || get(reservation, 'vehicleCategory.name'),
                  tariff: get(reservation, 'vehicle.tariff') || get(reservation, 'vehicleCategory.tariff'),
                },
              });
            });
        }
      }

      this.setBooking({
        operator,
        isPreBooking,
        isBookingBehalfEmployee: this.isBookingBehalfEmployee,
        canUseBehalfEmployee: this.showEmployeeSelector,
        user: this.getUserDataUsedInBooking(),
        profile: this.currentProfile,
        driver: this.getDriverUsedInBooking(),
        vehicleCategory: get(reservation, 'vehicleCategory'),
        vehicle: {
          ...get(bookingSummary, 'vehicle', {}),
          category: get(reservation, 'vehicle.category') || get(reservation, 'vehicleCategory.name'),
          tariff: get(reservation, 'vehicle.tariff') || get(reservation, 'vehicleCategory.tariff'),
        },
        location: this.getLocationDataUsedInBooking(get(reservation, 'location')),
        pickUpLocation: find(this.operatorLocations, {
          uuid: get(reservation, 'location.uuid'),
        }),
        booking_type: reservation.booking_type,
        start: reservation.start,
        end: reservation.end,
        start_availability: reservation.start_availability,
        end_availability: reservation.end_availability,
        periods: get(this.selectedRecurringBooking, 'periods'),
      });

      return {
        bookingSummary,
      };
    },

    checkBookingTypesPermissions() {
      return new Promise(resolve => {
        if (!this.canMakeRegularBookings && !this.canMakeInterventionBookings) {
          this.logout();
          return;
        }

        if (!this.canMakeRegularBookings && this.canMakeInterventionBookings) {
          this.$router.push({ name: BookingRoutesNames.myInterventions });
          return;
        }

        resolve();
      });
    },

    changeOnBehalfEmployee(employee) {
      this.employeeDriver = employee;
      this.changeEmployee(employee);
    },

    setDefaultEmployeeDriver() {
      // Get the employee when user goes back from Booking Summary
      const onBehalfUuid = get(this, 'currentEmployeeProfile.uuid');
      const employeeDeeplink = get(this, 'externalBookingData.user_uuid');
      const userUuid = get(this, 'userData.uuid');

      // When user is using the business profile, we have to select a default profile uuid.
      // Then it will be overrode when BookingOnBehalf component selects a new employee
      this.setEmployeeProfile({ business_profile_uuid: this.currentProfile.getUUID() });

      this.employeeDriver = {
        uuid: onBehalfUuid || employeeDeeplink || userUuid,
      };
    },

    onViewPricingPlans() {
      this.$router.push({
        name: personalProfileRoutesNames.tariffs,
      });
    },
    async onSetDefaultTariffCallback() {
      this.refreshAvailability(true);
      this.fetchUserData();
    },

    closeModal() {
      this.trackPageView();
      this.modalSetup.isOpen = false;
    },

    openBookingBlockerModal({ error }) {
      this.modalSetup = {
        isOpen: true,
        props: {
          ...genericUserBlockArgs(this.$t),
          title: this.$t('user_validations.create_booking.generic_titles.can_not_use_fleet', { operator: this.appName }),
          description: error,
          primaryCallToAction: this.closeModal,
        },
      };
    },

    openSignUpCompleteModal({ type }) {
      this.trackModalView({ modalName: GTM_MODAL_EVENTS.accountCreationConfirmationModal });
      this.modalSetup = {
        isOpen: true,
        props: {
          ...genericSuccessArgs(this.$t),
          title: this.$t(`refactor.feedback_modal.${type}`, { operator: this.appName }),
          primaryCallToAction: this.closeModal,
          primaryCallToActionText: this.$t('buttons.continue'),
        },
      };
    },
  },
};
</script>
